import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Stack,
    TextField,
    Typography
} from '@mui/material';
import React, {ChangeEvent, useState} from 'react';
import CategorySelect from "./CategorySelect";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {CategoryDto} from "../../../School/actions/category.type";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import {createGroupApi} from "../../actions/api";
import {useTranslation} from "react-i18next";

interface GroupForm {
    title: string | null;
    category: CategoryValue;
}

interface GroupDialogProps {
    open: boolean;
    close: () => void;
    updateGroups: () => void;
}

type CategoryValue = CategoryDto['id'] | null

const NewGroupDialog = ({open, close, updateGroups}: GroupDialogProps) => {

    const {t} = useTranslation();

    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [groupForm, setGroupForm] = useState<GroupForm>({
        title: '',
        category: '',
    });

    const [createLoading, setCreateLoading] = useState<boolean>(false);

    const changeCategory = (value: CategoryValue) => {
        setGroupForm((prev) => {
            return {
                ...prev,
                category: value
            }
        })
    };

    const changeTitle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setGroupForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };

    const handleSubmit = () => {

        if (!groupForm.category) {
            snackbar.errorMessage(t('school_page.group_list.new_group_dialog.message'));
            return;
        } else {
            const schoolId = schoolInfo.id;

            const formData = {
                title: groupForm.title ? groupForm.title : null,
                status: 1,
                category_id: groupForm.category,
                start_date: null,
                end_date: null,
                school_id: schoolId
            };

            createGroup(formData);
        }

    }

    const createGroup = (formData: any) => {
        setCreateLoading(true);
        createGroupApi(formData)
            .then((res) => {
                snackbar.successMessage(res.data.message);
                cleanForm();
                updateGroups();
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setCreateLoading(false);
            })
    }

    const cleanForm = () => {
        setGroupForm({
            title: '',
            category: '',
        })
    }

    return (
        <>
            <Dialog open={open} onClose={() => close()}>
                <DialogTitle>Создание группы</DialogTitle>
                <DialogContent>
                    <Typography sx={{mb: 1.5}}>
                        {t('school_page.group_list.new_group_dialog.create_text')} <i><b>{schoolInfo.title || '...'}</b></i>
                    </Typography>
                    <TextField fullWidth name={'title'} value={groupForm.title} onChange={(e) => changeTitle(e)} label={t('school_page.group_list.new_group_dialog.label')}/>
                    <CategorySelect fullWidth value={groupForm.category}
                                    onChange={(value) => changeCategory(value)}/>

                    {/*<Stack*/}
                    {/*  display={'flex'}*/}
                    {/*  flexDirection={'column'}*/}
                    {/*  sx={{*/}
                    {/*      mt: 2,*/}
                    {/*      p: 2,*/}
                    {/*      borderRadius: '16px',*/}
                    {/*      background: 'rgba(0,182,155,0.15)'*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*    <Typography*/}
                    {/*        fontSize={'14px'}*/}
                    {/*    >*/}
                    {/*        Уважаемый пользователь, для регистрации группы обучающихся необходимо следующее:*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        fontSize={'14px'}*/}
                    {/*        sx={{*/}
                    {/*            mt: 2*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        1.	Регистрация каждого обучающегося в ИС Avtomektep.kz в качестве курсанта.*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        fontSize={'14px'}*/}
                    {/*    >*/}
                    {/*        2.	Для регистрации в качестве курсанта, обучающемуся необходимо пройти на сайт <a href={'https://avtomektep.kz/'}>https://avtomektep.kz/</a> и нажать на кнопку «Регистрация в качестве курсанта»*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        fontSize={'14px'}*/}
                    {/*    >*/}
                    {/*        3.	Обучающемуся необходимо заполнить все обязательные поля регистрации.*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        fontSize={'14px'}*/}
                    {/*    >*/}
                    {/*        4.	Далее после регистрации в качестве курсанта обучающемуся необходимо оплатить пошлину в размере 1 000 (одна тысяча) тенге для защищенного хранения персональных базы данных в ИС Avtomektep.kz.*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        fontSize={'14px'}*/}
                    {/*    >*/}
                    {/*        5.	По завершению регистрации в качестве курсанта и оплаты пошлины всеми обучающимися, регистрация группы будет доступно.*/}
                    {/*    </Typography>*/}
                    {/*</Stack>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => close()}>{t('school_page.group_list.new_group_dialog.close_btn')}</Button>
                    {!createLoading && (<Button
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        {t('school_page.group_list.new_group_dialog.create_btn')}
                    </Button>)}

                    {createLoading && (<CircularProgress />)}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewGroupDialog;