import axios from 'axios';
import { apiConfigAuthorized, apiConfigRefreshToken, BACKEND_URL, FRONTEND_URL } from './apiVariables';
import Cookies from "js-cookie";
import {useUserStore} from "../store/UserStore";

const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
});

axiosInstance.interceptors.request.use((request) => {
    if (!request.headers.Authorization && Cookies.get('accessToken')) {
        const accessToken = Cookies.get('accessToken');
        request.headers['Authorization'] = 'Bearer ' + accessToken;
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }
    return request;
})

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
            try {
                const newTokens = await refreshAccessToken();
                originalRequest.headers['Authorization'] = 'Bearer ' + newTokens.accessToken;
                return axiosInstance(originalRequest);
            } catch (e) {
                logoutToAuth();
            }
        }
        if (error.response.status === 403) {
            logoutToAuth();
        }
        return Promise.reject(error);
    }
);

export async function refreshAccessToken(isBadRequest = false) {
    const refreshToken = Cookies.get('refreshToken');

    if (!refreshToken) {
        useUserStore.getState().logOut();
        window.location.assign(`${FRONTEND_URL}/auth`);
    }

    try {
        const response = await axios.get(`${BACKEND_URL}/auth/refresh`, apiConfigRefreshToken());
        setTokens(response.data);
        return response.data;
    } catch (e) {
        if (isBadRequest) {
            logoutToAuth();
        } else {
            logoutToAuth();
        }
    }
}

export const setTokens = ({accessToken, refreshToken} : any) => {
    Cookies.set('accessToken', accessToken);
    Cookies.set('refreshToken', refreshToken);
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
}

export const clearTokens = () => {
    Cookies.set('accessToken', '');
    Cookies.set('refreshToken', '');
    axiosInstance.defaults.headers.common['Authorization'] = null;
}

export const logoutFromSystem = () => {
    axiosInstance.post(`${BACKEND_URL}/auth/logout`, {}, apiConfigAuthorized())
        .then(() => {
            logoutToAuth();
        })
        .catch(() => {
            logoutToAuth();
        })
        .finally(() => {
            clearTokens();
        })
}

export const logoutToAuth = () => {
    useUserStore.getState().logOut();
    setTimeout(() => {
        window.location.assign(`${FRONTEND_URL}/auth`);
    }, 500)
}

export const checkAuth = () => {
    return axiosInstance.get(`${BACKEND_URL}/auth/check/token`, apiConfigAuthorized());
}

export const apiGetFeatureEnabled = (moduleName: string) => {
    return axiosInstance.get(`${BACKEND_URL}/feature-toggles/${moduleName}`);
}

export default axiosInstance;