import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";
import {StudentInfoType} from "../../global/actions/types.api";

export const apiGetLastNews = () => {
  return axiosInstance.get(`${BACKEND_URL}/news/last`);
}

export const apiGetNewsById = (newsId: number) => {
  return axiosInstance.get(`${BACKEND_URL}/news/${newsId}`);
}

export const apiGetStudentsFromAis = (iin: string) => {
  return axiosInstance.get(`${BACKEND_URL}/students/integration/${iin}`);
}

export interface InfoByIinType {
  Success: boolean;
  StudentsCount: number;
  Students: StudentInfoByIinType;
}

export interface StudentInfoByIinType {
  Student: StudentInfoType[];
}