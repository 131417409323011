import React, {useEffect, useState} from 'react';
import {
    Avatar,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import useLoadingHook from "../../../global/hooks/UseLoadingHook";
import {apiAttachStudentToUser, apiCreateUserForStudent, apiGetStudentUserByIIN} from "../../actions/api";
import {Person} from "@mui/icons-material";
import {validateEmail, validatePhoneNumber} from "../../../global/helpers/validators";
import LoadingButton from "../../../global/components/LoadingButton";

interface ModalProps {
    open: boolean,
    onClose: () => void,
    studentId: number,
    groupInfo: any,
    fetchGroupInfo: () => void
}

const CreateStudentUserModal = ({open, onClose, studentId, groupInfo, fetchGroupInfo} : ModalProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const [studentUserByIIN, getLoading, , getStudentUserByIIN, resetStudentUserByIIN] = useLoadingHook(apiGetStudentUserByIIN);
    const [studentInfo, setStudentInfo] = useState<any>({
        student_id: studentId,
        last_name: '',
        first_name: '',
        patronymic: '',
        iin: '',
        email: '',
        phone: '',
        generated_user_password: ''
    });

    const [createUserResponse, createUserLoading, createUserError, createUserForStudent, resetCreateUserResponse] = useLoadingHook(apiCreateUserForStudent);

    useEffect(() => {
        const desiredStudent = groupInfo.students.find((student: any) => student.id === studentId);

        setStudentInfo((prev: any) => {
            return {
                ...prev,
                last_name: desiredStudent.last_name,
                first_name: desiredStudent.first_name,
                patronymic: desiredStudent.patronymic ? desiredStudent.patronymic : '',
                iin: desiredStudent.iin,
                email: desiredStudent.email ? desiredStudent.email : '',
                phone: desiredStudent.phone ? desiredStudent.phone : '',
            }
        })
    }, [open]);

    useEffect(() => {
        if (studentInfo.iin) {
            resetStudentUserByIIN();
            resetCreateUserResponse();
            getStudentUserByIIN(studentInfo.iin);
        }
    }, [open]);

    const HasMatchUser = () => {

        const [attachResponse, attachLoading, attachError, attachStudentToUser] = useLoadingHook(apiAttachStudentToUser)

        const handleAttach = () => {
            attachStudentToUser({
                student_id: studentInfo.student_id,
                user_id: studentUserByIIN.id
            })
        };

        useEffect(() => {
            if (attachResponse) {
                snackbar.successMessage(attachResponse.message)
            }
            if (attachError) {
                snackbar.errorMessage(attachError.response?.data?.message ? attachError.response?.data?.message : 'Произошла непредвиденная ошибка')
            }
        }, [attachResponse, attachError]);

        return (
            <Stack
                flexDirection={'column'}
                gap={'16px'}
            >
                <Typography>
                    Внимание! По ИИН данного студента ({studentInfo.last_name} {studentInfo.first_name} - {studentInfo.iin}) - уже существует пользователь на портале Avtomektep.KZ.
                </Typography>
                <Typography>
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            p: '24px',
                            width: 'fit-content'
                        }}
                    >
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            <Person />
                        </Avatar>
                        <Stack>
                            <Typography>#{studentUserByIIN.id}. {studentUserByIIN.last_name} {studentUserByIIN.first_name}</Typography>
                            <Typography fontSize={'12px'}>ИИН: {studentUserByIIN.iin}</Typography>
                        </Stack>
                    </Paper>
                </Typography>
                <Stack>
                    <LoadingButton
                        sx={{
                            width: 'fit-content',
                        }}
                        variant={'contained'}
                        loading={attachLoading}
                        onClick={handleAttach}
                    >
                        Прикрепить пользователя к студенту
                    </LoadingButton>
                </Stack>
            </Stack>
        )
    };

    const handleStudentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setStudentInfo((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateEmail(studentInfo.email)) {
            snackbar.errorMessage('Неправильный формат электронной почты. Убедитесь, что адрес соответствует формату "example@domain.com".');
            return;
        }

        if (!validatePhoneNumber(studentInfo.phone)) {
            snackbar.errorMessage('Неправильный формат номера телефона. Убедитесь, что номер начинается с "+7" и содержит 11 цифр, например: +7***1112233.');
            return;
        }

        createUserForStudent(studentInfo)
    };

    useEffect(() => {
        if (createUserResponse) {
            snackbar.successMessage(`Личный кабинет пользователя для студента ${studentInfo.last_name} ${studentInfo.first_name} был успешно создан.`)
            fetchGroupInfo();
        }

        if (createUserError) {
            console.log(createUserError);
            snackbar.errorMessage(createUserError.response.data.message ? createUserError.response.data.message : 'Произошла непредвиденная ошибка')
        }
    }, [createUserResponse, createUserError]);

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={getLoading ? () => {snackbar.errorMessage('Невозможно выйти из диалогового окна во время запроса')}
                : createUserLoading ? () => {snackbar.errorMessage('Невозможно выйти из диалогового окна во время запроса')}
                    : onClose}
        >
            <DialogTitle>
                Создание/прикрепление личного кабинета студента
            </DialogTitle>
            <DialogContent>
                {getLoading && (
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <CircularProgress />
                    </Stack>
                )}

                {studentUserByIIN && !getLoading && (
                    <HasMatchUser />
                )}

                {!studentUserByIIN && !getLoading && (
                    <Stack
                        flexDirection={'column'}
                        gap={'10px'}
                    >
                        <Typography>
                            Внимание! По ИИН данного студента ({studentInfo.last_name} {studentInfo.first_name} - {studentInfo.iin}) - отсутствует пользователь на портале Avtomektep.KZ.
                        </Typography>
                        <Typography>
                            Для прохождения онлайн внутришкольных экзаменов - необходимо создать для каждого студента личный кабинет на портале.
                        </Typography>
                        <form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                margin: '15px 0'
                            }}
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                name={'last_name'}
                                value={studentInfo.last_name}
                                label={'Фамилия'}
                                disabled={true}
                            />
                            <TextField
                                name={'first_name'}
                                value={studentInfo.first_name}
                                label={'Имя'}
                                disabled={true}
                            />
                            <TextField
                                name={'patronymic'}
                                value={studentInfo.patronymic}
                                label={'Отчество'}
                                disabled={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                name={'iin'}
                                value={studentInfo.iin}
                                label={'ИИН'}
                                disabled={true}
                            />
                            <TextField
                                name={'email'}
                                value={studentInfo.email}
                                label={'Электронная почта'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={createUserLoading || createUserResponse}
                                onChange={handleStudentChange}
                            />
                            <TextField
                                name={'phone'}
                                value={studentInfo.phone}
                                label={'Номер телефона*'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={createUserLoading || createUserResponse}
                                onChange={handleStudentChange}
                            />

                            <LoadingButton
                                variant={'contained'}
                                type={'submit'}
                                loading={createUserLoading}
                            >
                                Создать личный кабинет студента
                            </LoadingButton>
                        </form>
                    </Stack>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CreateStudentUserModal;