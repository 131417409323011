import React, {useEffect, useState} from 'react';
import {
    Avatar, Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import LoadingButton from "../../../global/components/LoadingButton";
import useLoadingHook from "../../../global/hooks/UseLoadingHook";
import {deleteStudentFromGroup} from "../../actions/api";
import {toast} from "react-toastify";

interface ModalProps {
    open: boolean,
    onClose: () => void,
    studentId: number,
    groupInfo: any,
}

const DeleteStudentDialogModal = ({open, onClose, studentId, groupInfo} : ModalProps) => {

    const [deleteResponse, deleteLoading, deleteError, requestDelete] = useLoadingHook(deleteStudentFromGroup)

    const [studentInfo, setStudentInfo] = useState<any>({
        student_id: studentId,
        last_name: '',
        first_name: '',
        patronymic: '',
        iin: '',
        reason: '',
    });

    useEffect(() => {
        const desiredStudent = groupInfo.students.find((student: any) => student.id === studentId);

        setStudentInfo((prev: any) => {
            return {
                ...prev,
                last_name: desiredStudent.last_name,
                first_name: desiredStudent.first_name,
                patronymic: desiredStudent.patronymic ? desiredStudent.patronymic : '',
                iin: desiredStudent.iin,
            }
        })
    }, [studentId]);


    const handleDelete = () => {
        if (!studentInfo.reason) {
            toast.error("Необходимо вписать причину удаления курсанта из учебной группы");
            return;
        } else {
            requestDelete(studentId, studentInfo.reason)
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStudentInfo((prev: any) => {
            return {
                ...prev,
                reason: e.target.value,
            }
        })
    };

    useEffect(() => {
        if (deleteResponse) {
            toast.success('Курсант успешно удален из учебной группы. Обновите страницу группы для обновления информации.')
        }
    }, [deleteResponse]);

    useEffect(() => {
        if (deleteError) {
            toast.error(deleteError?.response?.data?.message ?? 'Произошла непредвиденная ошибка')
        }
    }, [deleteError]);

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={!deleteLoading ? onClose : () => {}}
        >
            <DialogTitle>
                Подтверждение удаления курсанта #{studentInfo.student_id} {studentInfo.last_name} {studentInfo.first_name}
            </DialogTitle>
            <DialogContent>
                <Stack
                    flexDirection={'column'}
                    gap={'10px'}
                >
                    <Typography>
                        Пожалуйста, подтвердите удаление курсанта или отмените действие в данном окне.
                    </Typography>
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            margin: '15px 0'
                        }}
                    >
                        <TextField
                            name={'last_name'}
                            value={studentInfo.last_name}
                            label={'Фамилия'}
                            disabled={true}
                        />
                        <TextField
                            name={'first_name'}
                            value={studentInfo.first_name}
                            label={'Имя'}
                            disabled={true}
                        />
                        <TextField
                            name={'patronymic'}
                            value={studentInfo.patronymic}
                            label={'Отчество'}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            name={'iin'}
                            value={studentInfo.iin}
                            label={'ИИН'}
                            disabled={true}
                        />
                        <TextField
                          name={'reason'}
                          value={studentInfo.reason}
                          label={'Причина удаления студента'}
                          onChange={handleChange}
                        />

                        <Stack
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                            gap={'16px'}
                            sx={{
                                mt: '16px'
                            }}
                        >
                            <LoadingButton
                                variant={'outlined'}
                                color={'info'}
                                loading={deleteLoading}
                                onClick={!deleteLoading ? onClose : () => {}}
                            >
                                Отмена
                            </LoadingButton>

                            <LoadingButton
                              variant={'contained'}
                              onClick={handleDelete}
                              color={'error'}
                              loading={deleteLoading}
                            >
                                Подтвердить удаление
                            </LoadingButton>
                        </Stack>
                    </form>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default DeleteStudentDialogModal;