import React, {FormEvent, useEffect, useState} from 'react';
import {
  Box, CircularProgress, FormControl, InputBase, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField,
  Typography
} from '@mui/material';
import PageHeader from "../../global/components/PageHeader";
import {useParams} from "react-router-dom";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {wrapperSx} from "../../global/helpers/globalstyles";
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import {useTranslation} from "react-i18next";
import {apiChangeSchoolInfo, getSchoolInfo} from "../actions/api";
import {ChangeSchoolInfoType, SchoolInfoType} from "../../global/actions/types.api";
import LoadingButton from "../../global/components/LoadingButton";
import {getLocalitiesByRegionId, getRegions} from "../../Auth/actions/api";
import {formatDate, formatDateForInput} from "../../global/helpers/text";
import {useUserStore} from "../../global/store/UserStore";

const SchoolChangeInfoPage = () => {

  const {id} = useParams();

  const {t} = useTranslation();

  const snackbar = useSnackbarStore((state) => state);
  const userStore = useUserStore((state) => state);

  const [schoolInfo, schoolInfoLoading, schoolInfoError, fetchSchoolInfo] = useLoadingHook<SchoolInfoType>(getSchoolInfo);
  const [regionsList, regionsListLoading, regionsListError, fetchRegionsList] = useLoadingHook(getRegions);
  const [localitiesList, localitiesListLoading, localitiesListError, fetchLocalitiesList] = useLoadingHook(getLocalitiesByRegionId);
  const [changeInfoResponse, changeInfoLoading, changeInfoError, requestChangeInfo] = useLoadingHook(apiChangeSchoolInfo);

  const [schoolForm, setSchoolForm] = useState<ChangeSchoolInfoType>({
    id: Number(id),
    title: "",
    bin: "",
    director: "",
    director_iin: "",
    director_qualification_certificate_number: '',
    director_qualification_certificate_issuer: '',
    director_qualification_certificate_issue_date: '',
    director_qualification_certificate_end_date: '',
    email: "",
    address: "",
    phone1: "",
    phone2: "",
    region: "",
    locality: "",
    signData: ""
  });
  const [signString, setSignString] = useState<string>('');

  const [ws, setWs] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<string[]>([]);
  const [callback, setCallback] = useState<string | null>(null);

  const connectNCAWebSocket = () => {
    const websocket = new WebSocket('wss://127.0.0.1:13579/');

    websocket.onopen = () => {
      snackbar.infoMessage('Соединение с NCALayer успешно подключено');
    }

    websocket.onmessage = (event) => {
      setMessages(prevMessages => [...prevMessages, event.data]);

      const result = JSON.parse(event.data);

      if (result) {
        const responseObject = result.responseObject;

        if (responseObject) {
          setSignString(responseObject)
        }
      }
    }

    websocket.onerror = (event) => {
      snackbar.errorMessage('Не удалось установить соединение с NCALayer. Включите NCALayer и попробуйте попытку')
      if (window.confirm('Не удалось установить соединение с NCALayer. Нажмите OK для повторной попытки')) {
        connectNCAWebSocket();
      }
    }

    websocket.onclose = () => {
      setWs(null); // Обновляем состояние, указывая на закрытие соединения
    };

    setWs(websocket ? websocket : null); // Обновляем состояние с новым экземпляром WebSocket
  };

  useEffect(() => {
    connectNCAWebSocket();

    return () => {
      ws?.close();
    }
  }, [])

  useEffect(() => {
    fetchSchoolInfo(Number(id))
    fetchRegionsList();
  }, []);

  useEffect(() => {
    if (schoolInfo) {
      setSchoolForm({
        id: schoolInfo.id,
        title: schoolInfo.title,
        bin: schoolInfo.bin,
        director: schoolInfo.director,
        director_iin: schoolInfo.director_iin,
        director_qualification_certificate_number: schoolInfo.director_qualification_certificate_number ?? '',
        director_qualification_certificate_issuer: schoolInfo.director_qualification_certificate_issuer ?? '',
        director_qualification_certificate_issue_date: schoolInfo.director_qualification_certificate_issue_date ?? '',
        director_qualification_certificate_end_date: schoolInfo.director_qualification_certificate_end_date ?? '',
        email: schoolInfo.email,
        address: schoolInfo.address,
        phone1: schoolInfo.phone1 ?? '',
        phone2: schoolInfo.phone2 ?? '',
        region: schoolInfo.region,
        locality: schoolInfo.locality,
        signData: ''
      })
    }
  }, [schoolInfo, schoolInfoError]);

  function signXml(storageName : string, keyType : string, xmlToSign : string, callBack : string) {
    var signXml = {
      "module": "kz.gov.pki.knca.commonUtils",
      "method": "signXml",
      "args": [storageName, keyType, xmlToSign, "", ""]
    };
    setCallback(callBack);
    ws?.send(JSON.stringify(signXml));
  };

  const xmsSignCall = (xml: any) => {
    const storageType = "PKCS12";
    const xmlToSign = xml;

    signXml(storageType, "SIGNATURE", xmlToSign, "signXmlBack");
  };

  const handleRegionChange = (e: SelectChangeEvent<any>) => {
    setRegion(e.target.value)
  }

  const handleLocalitiesChange = (e: SelectChangeEvent<string>) => {
    setLocality(e.target.value)
  }

  const setRegion = (chosenRegion: any) => {
    setSchoolForm((prev) => {
      return {
        ...prev,
        region: chosenRegion
      }
    })

    regionsList?.map((region: any) => {
      if (region.name_ru === chosenRegion) {
        const regionId = region.id;
        fetchLocalitiesList(regionId)
      }
    })
  }

  useEffect(() => {
    if (schoolInfo && regionsList) {
      regionsList?.map((region: any) => {
        if (region.name_ru === schoolInfo.region) {
          const regionId = region.id;
          fetchLocalitiesList(regionId)
        }
      })
    }
  }, [regionsList, schoolInfo]);

  const setLocality = (chosenLocality: any) => {

    if (chosenLocality === 'Другое (вписать в адрес)') {
      setSchoolForm((prev) => {
        return {
          ...prev,
          locality: 'Другое (вписать в адрес)'
        }
      })
      return;
    }

    setSchoolForm((prev) => {
      return {
        ...prev,
        locality: chosenLocality
      }
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setSchoolForm((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    requestChangeInfo(schoolForm);
  }

  useEffect(() => {
    if (changeInfoResponse) {
      snackbar.successMessage(changeInfoResponse.message);
    }
  }, [changeInfoResponse]);

  useEffect(() => {
    if (changeInfoError) {
      snackbar.errorMessage(changeInfoError?.response?.data?.message ?? 'Произошла непредвиденная ошибка');
    }
  }, [changeInfoError]);

  useEffect(() => {
    if (signString) {
      setSchoolForm((prev) => {
        return {
          ...prev,
          signData: signString
        }
      })
    }
  }, [signString]);

  if (schoolInfoLoading || changeInfoLoading) {
    return (
      <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <PageHeader />
        <Box sx={{...wrapperSx}}>

          <Box>
            <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
              <Typography sx={{mt: 3}} variant={'h5'}><b>{t('school_page.change_info_page.title')}</b></Typography>
              <Typography variant="subtitle2">

              </Typography>
            </Stack>
          </Box>
          <Stack flexDirection={'row'} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader />
      <Box sx={{...wrapperSx}}>
        <Typography sx={{mt: 3}} variant={'h5'}><b>{t('school_page.change_info_page.title')}</b></Typography>
        <Stack
          flexDirection={'column'}
          sx={{
            my: 1,
            py: 1
          }}
        >
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              name={'bin'}
              value={schoolForm.bin}
              disabled={true}
              label={t('school_page.change_info_page.bin')}
              sx={{width: '100%'}}
            />
            <TextField
              name={'title'}
              value={schoolForm.title}
              label={t('school_page.change_info_page.title')}
              sx={{width: '100%'}}
              disabled={userStore.isObserver}
              required={true}
              onChange={handleChange}
            />
            <TextField
              name={'director'}
              value={schoolForm.director}
              label={t('school_page.change_info_page.director')}
              sx={{width: '100%'}}
              disabled={userStore.isObserver}
              required={true}
              onChange={handleChange}
            />
            <TextField
              name={'director_iin'}
              value={schoolForm.director_iin}
              label={t('school_page.change_info_page.director_iin')}
              sx={{width: '100%'}}
              disabled={userStore.isObserver}
              required={true}
              onChange={handleChange}
            />
            <TextField
              type={'text'}
              placeholder={t('auth.first-step.director_qualification_certificate_number')}
              value={schoolForm.director_qualification_certificate_number}
              name={'director_qualification_certificate_number'}
              onChange={handleChange}
              required={true}
              disabled={userStore.isObserver}
            />
            <TextField
              type={'text'}
              placeholder={t('auth.first-step.director_qualification_certificate_issuer')}
              value={schoolForm.director_qualification_certificate_issuer}
              name={'director_qualification_certificate_issuer'}
              onChange={handleChange}
              required={true}
              disabled={userStore.isObserver}
            />
            <TextField
              name={'director_qualification_certificate_issue_date'}
              type={'date'}
              value={formatDateForInput(schoolForm.director_qualification_certificate_issue_date)}
              onChange={handleChange}
              label={t('auth.first-step.director_qualification_certificate_issue_date')}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={userStore.isObserver}
            />
            <TextField
              name={'director_qualification_certificate_end_date'}
              type={'date'}
              value={formatDateForInput(schoolForm.director_qualification_certificate_end_date)}
              onChange={handleChange}
              label={t('auth.first-step.director_qualification_certificate_end_date')}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={userStore.isObserver}
            />
            <TextField
              name={'email'}
              value={schoolForm.email}
              label={t('school_page.change_info_page.email')}
              sx={{width: '100%'}}
              disabled={userStore.isObserver}
              required={true}
              onChange={handleChange}
            />
            <TextField
              name={'address'}
              value={schoolForm.address}
              label={t('school_page.change_info_page.address')}
              sx={{width: '100%'}}
              disabled={userStore.isObserver}
              required={true}
              onChange={handleChange}
            />
            <TextField
              name={'phone1'}
              value={schoolForm.phone1}
              label={t('school_page.change_info_page.phone1')}
              sx={{width: '100%'}}
              disabled={userStore.isObserver}
              required={true}
              onChange={handleChange}
            />
            <TextField
              name={'phone2'}
              value={schoolForm.phone2}
              label={t('school_page.change_info_page.phone2')}
              sx={{width: '100%'}}
              disabled={userStore.isObserver}
              onChange={handleChange}
            />
            <FormControl>
              <InputLabel id={'region-label'}>{t('auth.first-step.region')}</InputLabel>
              <Select
                labelId={'region-label'}
                onChange={handleRegionChange}
                name={'region'}
                label={t('auth.first-step.region')}
                sx={{
                  borderRadius: '16px',
                  ".MuiOutlinedInput-notchedOutline": {
                    borderWidth: 0,
                    borderRadius: '16px',
                  }
                }}
                value={schoolForm.region}
                required={true}
                disabled={userStore.isObserver}
              >
                {regionsList?.map((region: any) => {
                  return (
                    <MenuItem
                      value={region.name_ru}
                      key={region.name_ru}
                    >
                      {region.name_ru}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            {localitiesList && <FormControl>
              <InputLabel id={'locality-label'}>{t('auth.first-step.locality')}</InputLabel>
              <Select
                labelId={'locality-label'}
                onChange={handleLocalitiesChange}
                name={'locality'}
                label={t('auth.first-step.locality')}
                sx={{
                  borderRadius: '16px',
                  ".MuiOutlinedInput-notchedOutline": {
                    borderWidth: 0,
                    borderRadius: '16px',
                  }
                }}
                value={schoolForm.locality}
                required={true}
                disabled={(localitiesList?.length === 0) || userStore.isObserver}
              >
                {localitiesList?.map((locality: any) => {
                  return (
                    <MenuItem
                      value={locality.name_ru}
                      key={locality.name_ru}
                    >
                      {locality.name_ru}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>}

            {!userStore.isObserver && <LoadingButton
              onClick={() => {
                ws ? xmsSignCall('<signData>Согласен с отправляемыми данными</signData>') : connectNCAWebSocket()
              }}
              variant={'contained'}
              sx={{width: 'auto', height: '56px'}}
              disabled={!ws}
            >
              {!signString ? 'Подписать заявку на изменение информации' : 'Переподписать другим ЭЦП'}
            </LoadingButton>}

            {!userStore.isObserver && signString && <LoadingButton
              variant={'outlined'}
              onClick={() => {console.warn(schoolForm)}}
              type={'submit'}
            >
              Изменить информацию
            </LoadingButton>}
          </form>
        </Stack>
      </Box>
    </Box>
  );
};

export default SchoolChangeInfoPage;