import React, {useEffect, useState} from 'react';
import {Avatar, Box, Button, Skeleton, Stack, Typography, useTheme} from "@mui/material";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate} from "react-router-dom";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {getSchoolAttributes, getUserSchools} from "../../School/actions/api";
import {SchoolAttributesType, SchoolInfoType} from "../../global/actions/types.api";
import {Add, DirectionsCar, HdrPlus, PlusOne} from "@mui/icons-material";
import {MiniCategoriesGrid, MiniSubCategoriesGrid} from "../../School/components/CategoriesGrid";
import {getUserTickets} from "../../Tickets/actions/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import TicketsUserGrid from "../../Tickets/components/TicketsUserGrid";
import {NewsSection} from "../../Landing/pages/SchoolLandingPage";
import {useTranslation} from "react-i18next";

interface MiniAttributesCardProps {
  count: number | string;
  title: string;
  loading?: boolean;
}

const ManagerMainPage = () => {

  const navigate = useNavigate();
  const currentUser = useUserStore((state) => state);

  const {t} = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [schoolsList, schoolsListLoading, schoolsListError, requestSchoolsList] = useLoadingHook<SchoolInfoType[]>(getUserSchools);
  const [schoolAttributes, schoolAttributesLoading, schoolAttributesError, requestSchoolAttributes] = useLoadingHook<SchoolAttributesType>(getSchoolAttributes);
  const [ticketsList, ticketsListLoading, ticketsListError, requestTicketsList] = useLoadingHook(getUserTickets);

  const [currentSchoolIndex, setCurrentSchoolIndex] = useState(0);

  useEffect(() => {
    requestSchoolsList();
    requestTicketsList();
  }, []);

  useEffect(() => {
    if (schoolsList) {
      const schoolId = schoolsList[currentSchoolIndex].id;

      requestSchoolAttributes(Number(schoolId));
      setCurrentSchoolIndex(0);
    }
  }, [schoolsList]);

  useEffect(() => {
    if (schoolsList) {
      const schoolId = schoolsList?.[currentSchoolIndex].id;

      requestSchoolAttributes(Number(schoolId));
    }
  }, [currentSchoolIndex]);

  const handleChangeSchoolIndex = (type: string) => {
    if (type === 'next') {
      if (schoolsList?.length === currentSchoolIndex + 1) {
        setCurrentSchoolIndex(0);
      } else {
        setCurrentSchoolIndex((prev) => prev + 1);
      }
    }

    if (type === 'previous') {
      if (currentSchoolIndex === 0) {
        setCurrentSchoolIndex(schoolsList?.length ? schoolsList?.length - 1 : 0);
      } else {
        setCurrentSchoolIndex((prev) => prev - 1)
      }
    }
  }

  const goToTicket = (ticketId: number) => {
    navigate(`/admin/tickets/${ticketId}`);
  }

  const MiniAttributesCard = ({title, count, loading}: MiniAttributesCardProps) => {
    if (loading) {
      return (
        <Skeleton
          variant={'rectangular'}
          width={118}
          height={106}
          sx={{
            p: '22px',
            background: '#f4f7fe',
            borderRadius: '15px',
          }}
        />
      )
    }

    return (
      <Stack
        flexDirection={'column'}
        justifyContent={'space-between'}
        sx={{
          p: '22px',
          background: '#f4f7fe',
          borderRadius: '15px',
          width: '118px',
          height: '106px'
        }}
      >
        <Typography
          fontSize={'36px'}
          lineHeight={'100%'}
          color={'#05304f'}
        >
          {count ?? 'Н/Д'}
        </Typography>
        <Typography
          fontSize={'14px'}
          lineHeight={'100%'}
          color={'#000'}
        >
          {title}
        </Typography>
      </Stack>
    )
  }

  return (
    <Box className="main-page"
         sx={{
           height: '100%',
           display: 'flex',
           flexDirection: 'column'
         }}
    >
      <PageHeader />
      <Box sx={{...wrapperSx, px: isMobile ? '10px' : '60px', pb: '60px'}}>
        <Typography
          fontSize={'24px'}
          lineHeight={'120%'}
          color={'#707EAE'}
          sx={{
            mt: '20px'
          }}
        >
          {t('main-pages.manager.hello')} {currentUser.first_name},
        </Typography>
        <Typography
          fontSize={isMobile ? '32px' : '40px'}
          lineHeight={'100%'}
          color={'#2b3674'}
          fontWeight={'bold'}
          sx={{
            mt: '11px'
          }}
        >
          {t('main-pages.manager.welcome')}
        </Typography>

        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'center' : 'flex-start'}
          sx={{
            mt: '46px'
          }}
          gap={'20px'}
        >
          <Stack
            flexDirection={'column'}
            alignItems={'center'}
            width={isMobile ? 'auto' : '427px'}
            height={'499px'}
            sx={{
              background: '#1e9af3',
              borderRadius: '20px',
              py: '40px',
              px: isMobile ? '10px': '0'
            }}
          >
            <Stack>
              <img src={'/images/new-design/avatar.png'} alt={''} />
            </Stack>
            <Typography
              fontSize={'26px'}
              lineHeight={'100%'}
              color={'#fff'}
              sx={{
                mt: '18px'
              }}
            >
              {currentUser?.last_name} {currentUser?.first_name}
            </Typography>
            <Typography
              fontSize={'18px'}
              lineHeight={'100%'}
              color={'rgba(255,255,255,0.5)'}
              sx={{
                mt: '14px'
              }}
            >
              {currentUser?.iin}
            </Typography>
            <Typography
              fontSize={'24px'}
              lineHeight={'100%'}
              color={'#fff'}
              sx={{
                mt: '42px'
              }}
            >
              {currentUser.phone ? currentUser.phone : t('main-pages.manager.no-phone')}
            </Typography>
            <Typography
              fontSize={'18px'}
              lineHeight={'100%'}
              color={'rgba(255,255,255,0.5)'}
              sx={{
                mt: '10px'
              }}
            >
              {currentUser?.email}
            </Typography>
            <Button
              variant={'outlined'}
              onClick={() => {navigate(`/change-password/${currentUser.id}`)}}
              color={'success'}
              sx={{
                width: '347px',
                height: '54px',
                border: '0px !important',
                mt: 'auto',
                background: 'rgba(255,255,255,0.16)',
                textTransform: 'none',
                color: '#fff',
                fontWeight: 'normal',
                ':hover': {
                  background: 'rgba(255,255,255,0.36)'
                }
              }}
            >
              {t('main-pages.manager.change-password')}
            </Button>
          </Stack>

          <Stack
            flexDirection={'column'}
            sx={{
              width: isMobile ? 'auto' : '873px',
              height: isMobile ? 'auto' : '499px',
              p: isMobile ? '10px' : '40px',
              background: '#fff',
              borderRadius: '20px'
            }}
          >
            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              gap={'41px'}
            >
              <Stack
                flexDirection={'row'}
                sx={{
                  width: isMobile ? 'auto': '387px',
                  minHeight: '147px',
                  background: '#f4f7fe',
                  borderRadius: '20px',
                  p: '24px 16px 24px 24px'
                }}
              >
                <Stack
                  flexDirection={'column'}
                >
                  <Typography
                    fontSize={'16px'}
                    lineHeight={'100%'}
                    color={'#1e9af3'}
                  >
                    ID {schoolsList?.[currentSchoolIndex].id}
                  </Typography>

                  <Stack
                    flexDirection={'column'}
                    sx={{
                      mt: 'auto'
                    }}
                    gap={'10px'}
                  >
                    <Typography
                      fontSize={'16px'}
                      lineHeight={'100%'}
                      color={'rgba(5,48,79,0.6)'}
                    >
                      {t('main-pages.manager.organization')}
                    </Typography>
                    <Typography
                      fontSize={'22px'}
                      lineHeight={'100%'}
                      color={'#05304f'}
                    >
                      {schoolsList?.[currentSchoolIndex].title}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  flexDirection={'column'}
                  sx={{
                    ml: 'auto'
                  }}
                >
                  <Avatar
                    sx={{color: '#1E9AF3', bgcolor: '#fff', mt: '-9px', width: 58, height: 58}}
                  >
                    <DirectionsCar fontSize="medium"/>
                  </Avatar>
                </Stack>
              </Stack>

              <Stack
                flexDirection={'column'}
                gap={'31px'}
                sx={{
                  mt: '6px'
                }}
              >
                <Stack
                  flexDirection={'column'}
                  gap={'10px'}
                >
                  <MiniCategoriesGrid categories={schoolsList?.[currentSchoolIndex]?.categories ?? []} />
                </Stack>

                <Stack
                  flexDirection={'column'}
                  gap={'10px'}
                >
                  <MiniSubCategoriesGrid categories={schoolsList?.[currentSchoolIndex]?.categories ?? []} />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              sx={{
                mt: '51px',
                width: '100%'
              }}
            >
              <Stack
                flexDirection={isMobile ? 'column' : 'row'}
                gap={'23px'}
              >
                <Stack
                  flexDirection={'column'}
                  gap={'10px'}
                >
                  <Typography
                    fontSize={'16px'}
                    lineHeight={'100%'}
                    color={'rgba(5,48,79,0.6)'}
                  >
                    {t('main-pages.manager.groups')}:
                  </Typography>

                  <Stack
                    flexDirection={'row'}
                    gap={'10px'}
                  >
                    <MiniAttributesCard
                      count={schoolAttributes?.groups?.total ?? 'Н/Д'}
                      title={t('main-pages.manager.total')}
                      loading={schoolAttributesLoading}
                    />

                    <MiniAttributesCard
                      count={schoolAttributes?.groups?.active ?? 'Н/Д'}
                      title={t('main-pages.manager.current')}
                      loading={schoolAttributesLoading}
                    />
                  </Stack>
                </Stack>

                <Stack
                  flexDirection={'column'}
                  gap={'10px'}
                >
                  <Typography
                    fontSize={'16px'}
                    lineHeight={'100%'}
                    color={'rgba(5,48,79,0.6)'}
                  >
                    {t('main-pages.manager.students')}:
                  </Typography>

                  <Stack
                    flexDirection={'row'}
                    gap={'10px'}
                  >
                    <MiniAttributesCard
                      count={schoolAttributes?.students?.total ?? 'Н/Д'}
                      title={t('main-pages.manager.total')}
                      loading={schoolAttributesLoading}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Stack
                flexDirection={'row'}
                gap={'10px'}
                sx={{
                  height: "fit-content",
                  mt: isMobile ? '20px' : "auto",
                  ml: "auto",
                }}
              >
                <Button
                  sx={{
                    width: 'fit-content',
                    height: 'fit-content',
                    borderRadius: '16px',
                    minWidth: 'fit-content',
                    p: 0
                  }}
                  onClick={() => {handleChangeSchoolIndex('previous')}}
                >
                  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="52" height="52" rx="16" fill="#F4F7FE"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M23.9141 32L17.9998 26L23.9141 20L26.4998 20L21.8283 25L33.4141 25L33.4141 27L21.8283 27L26.4998 32L23.9141 32Z"
                          fill="#05304F"/>
                  </svg>
                </Button>
                <Button
                  sx={{
                    width: 'fit-content',
                    height: 'fit-content',
                    borderRadius: '16px',
                    minWidth: 'fit-content',
                    p: 0
                  }}
                  onClick={() => {handleChangeSchoolIndex('next')}}
                >
                  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="52" y="52" width="52" height="52" rx="16" transform="rotate(-180 52 52)" fill="#F4F7FE"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M27.5 20L33.4142 26L27.5 32L24.9143 32L29.5858 27L18 27L18 25L29.5858 25L24.9143 20L27.5 20Z"
                          fill="#05304F"/>
                  </svg>
                </Button>
              </Stack>
            </Stack>

            <Button
              variant={'outlined'}
              sx={{
                mt: '32px',
                color: '#000',
                textTransform: 'none'
              }}
              onClick={() => {navigate(`/schools/${schoolsList?.[currentSchoolIndex]?.id}`)}}
            >
              {t('main-pages.manager.details')}
            </Button>
          </Stack>

        </Stack>

        <Stack
          flexDirection={'column'}
          sx={{
            background: '#fff',
            borderRadius: '20px',
            mt: '20px'
          }}
        >
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              mt: '47px',
              px: '40px',
              mb: '40px'
            }}
          >
            <Typography
              fontSize={'30px'}
              lineHeight={'100%'}
              color={'#05304F'}
            >
              {t('main-pages.manager.your-appointments')}
            </Typography>
            <Button
              variant={'contained'}
              startIcon={<Add />}
              sx={{
                px: '90px',
                mt: isMobile ? '20px' : '0'
              }}
              onClick={() => navigate('/tickets/new')}
            >
              {t('main-pages.manager.new-appointment')}
            </Button>
          </Stack>
          <TicketsUserGrid
            openAppeal={goToTicket}
            rows={ticketsList ? ticketsList : []}
            setTab={() => {}}
            tab={0}
            loading={ticketsListLoading}
            withoutTabs={true}
          />
        </Stack>

        <NewsSection
          authorized={true}
        />
      </Box>
    </Box>
  );
};

export default ManagerMainPage;