import React, {useEffect, useState} from 'react';
import {
    Avatar,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import LoadingButton from "../../../global/components/LoadingButton";

interface ModalProps {
    open: boolean,
    onClose: () => void,
    studentId: number,
    groupInfo: any,
}

const CopyStudentUserInfoModal = ({open, onClose, studentId, groupInfo} : ModalProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const [studentInfo, setStudentInfo] = useState<any>({
        student_id: studentId,
        last_name: '',
        first_name: '',
        patronymic: '',
        iin: '',
        email: '',
        phone: '',
        generated_user_password: ''
    });

    useEffect(() => {
        const desiredStudent = groupInfo.students.find((student: any) => student.id === studentId);

        setStudentInfo((prev: any) => {
            return {
                ...prev,
                last_name: desiredStudent.last_name,
                first_name: desiredStudent.first_name,
                patronymic: desiredStudent.patronymic ? desiredStudent.patronymic : '',
                iin: desiredStudent.iin,
                email: desiredStudent.email,
                phone: desiredStudent.phone,
                generated_user_password: desiredStudent.generated_user_password ?? null
            }
        })
    }, [studentId]);


    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`
Данные для входа на портал Avtomektep.KZ
            
ФИО пользователя: ${studentInfo.last_name} ${studentInfo.first_name} ${studentInfo.patronymic};
Электронная почта: ${studentInfo.email};
Номер телефона: ${studentInfo.phone};
Временный пароль: ${studentInfo.generated_user_password ? studentInfo.generated_user_password : '*Пользователь самостоятельно изменил пароль*'};
            `);
        } catch (err) {
            snackbar.errorMessage('Не удалось скопировать данные в буфер обмена.')
        }
    };

    const handleClickButton = () => {
        copyToClipboard()
            .then(() => {
                snackbar.successMessage(`Данные для входа студента ${studentInfo.last_name} ${studentInfo.first_name} успешно скопированы. Пожалуйста, отправьте их вашему студенту.`)
            })
    }

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Данные личного кабинета пользователя
            </DialogTitle>
            <DialogContent>
                <Stack
                    flexDirection={'column'}
                    gap={'10px'}
                >
                    <Typography>
                        У данного студента есть личный кабинет пользователя на портале Avtomektep.KZ. Нажмите на кнопку ниже для копирования данных в буфер обмена вашего устройства и отправьте данные для входа вашему студенту.
                    </Typography>
                    <Typography>
                        Для прохождения онлайн внутришкольных экзаменов - необходимо создать для каждого студента личный
                        кабинет на портале.
                    </Typography>
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            margin: '15px 0'
                        }}
                    >
                        <TextField
                            name={'last_name'}
                            value={studentInfo.last_name}
                            label={'Фамилия'}
                            disabled={true}
                        />
                        <TextField
                            name={'first_name'}
                            value={studentInfo.first_name}
                            label={'Имя'}
                            disabled={true}
                        />
                        <TextField
                            name={'patronymic'}
                            value={studentInfo.patronymic}
                            label={'Отчество'}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            name={'iin'}
                            value={studentInfo.iin}
                            label={'ИИН'}
                            disabled={true}
                        />
                        <TextField
                            name={'email'}
                            value={studentInfo.email}
                            label={'Электронная почта'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                        />
                        <TextField
                            name={'phone'}
                            value={studentInfo.phone}
                            label={'Номер телефона*'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                        />

                        <LoadingButton
                            variant={'contained'}
                            onClick={handleClickButton}
                        >
                            Скопировать данные пользователя для отправки
                        </LoadingButton>
                    </form>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CopyStudentUserInfoModal;