import {GroupStatus} from "../../Group/actions/group.type";

export interface UserInfoType {
    id: number,
    username?: string | null,
    iin: string,
    email: string | null,
    phone: string | null,
    first_name: string | null,
    last_name: string | null,
    patronymic: string | null,
    gender?: number,
    birthday?: string | null,
    address?: string | null,
    old_iin?: string | null,
    verified?: boolean,
    driving_license?: string | null,
    passport?: string | null,
    education?: string | null,
    specialization?: string | null,
    experience?: string | null,
    avatar?: string | null,
    flags?: any,
    register_type?: number,
    invite_id?: string | number | null,
    balance?: number | null,
    pdd_id?: number | null,
    iin_verified?: boolean | null,
    region?: string | null,
    locality?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    deleted_at?: null
}

export interface StudentInfoType {
    id: number;
    iin: string;
    first_name: string;
    last_name: string;
    patronymic: string | null;
    status: number;
    medical_certificate_number: string | null;
    medical_center_title: string | null;
    medical_center_bin: string | null;
    medical_examination_date: string | Date | null;
    group_id: number;
    grades_id: number | null;
    certificate_number: string | null;
    passed: boolean | null;
    user_id: number | null;
    free_tries: number | null;
    used_free_tries: number | null;
    paid_tries: number | null;
    used_paid_tries: number | null;
    generated_user_password: string | null;
    email: string;
    phone: string;
    created_at: string | Date | null;
    updated_at: string | Date | null;
    deleted_at: string | Date | null;
    group?: GroupInfoType | null;
    grades?: GradesInfoType | null;
    payment_access?: StudentPaymentAccessInfoType | null;

    school_title?: string;
    school_bin?: string;
    group_title?: string;
    group_status?: GroupStatus;
    category?: string;
    end_date?: string | Date | null;
    start_date?: string | Date | null;
    maintenance?: number;
    rules?: number;
    practice?: number;
}

export interface StudentPaymentAccessInfoType {
    id: number;
    student_id: number;
    paid: boolean;
    transaction_id: number;
    created_at: string | Date | null;
    updated_at: string | Date | null;
}

export interface GroupInfoType {
    id: number;
    school_id: number;
    title: string;
    status: number;
    is_archived: boolean;
    category_id: string;
    start_date: string | Date | null;
    end_date: string | Date | null;
    created_at: string | Date | null;
    updated_at: string | Date | null;
    deleted_at: string | Date | null;
    school?: SchoolInfoType | null;
}

export interface SchoolInfoType {
    id: number;
    title: string;
    bin: string;
    director: string;
    director_iin: string;
    director_qualification_certificate_number: string | null;
    director_qualification_certificate_issue_date: string | Date | null;
    director_qualification_certificate_end_date: string | Date | null;
    director_qualification_certificate_issuer: string | null;
    email: string;
    address: string;
    phone1: string | null;
    phone2: string | null;
    balance: number;
    avatar: string | null;
    region: string;
    locality: string;
    rating: string | null;
    created_at: string | Date | null;
    updated_at: string | Date | null;
    deleted_at: string | Date | null;
    categories?: any[];
}

export interface SchoolAttributesType {
    drivers: {
        total: number,
        active: number,
    },
    masters: {
        total: number,
        active: number,
    },
    teachers: {
        total: number,
        active: number
    },
    groups: {
        total: number,
        active: number,
    },
    transports: {
        total: number,
    },
    base: {
        created_at: string | null,
        updated_at: string | null
    },
    students?: {
        total: number
    }
}

export interface ChangeSchoolInfoType {
    id: number;
    title: string;
    bin: string;
    director: string;
    director_iin: string;
    director_qualification_certificate_number: string | null;
    director_qualification_certificate_issue_date: string | Date | null;
    director_qualification_certificate_end_date: string | Date | null;
    director_qualification_certificate_issuer: string | null;
    email: string;
    address: string;
    phone1: string | null;
    phone2: string | null;
    region: string;
    locality: string;
    signData: string;
}

export interface GradesInfoType {
    id: number;
    student_id: number;
    maintenance: number | null;
    rules: number | null;
    practice: number | null;
    created_at: string | Date | null;
    updated_at: string | Date | null;
}

export interface TheoryExamType {
    id: number;
    student_id: number;
    user_id: number;
    group_id: number;
    school_id: number;
    status: TheoryExamStatus;
    category_id: string;
    began_at: Date | null;
    ended_at: Date | null;
    total_questions: number;
    correct_answers: number;
    created_at: string | Date | null;
    updated_at: string | Date | null;
    deleted_at: string | Date | null;
    questions: TheoryExamQuestionType[];
    student?: StudentInfoType;
    proctoring_id: string;
    proctoring?: ProctoringInfoType
}

export interface TrialExamType {
    id: number;
    user_id: number;
    group_id: number;
    school_id: number;
    status: TrialExamStatus;
    category_id: string;
    began_at: Date | null;
    ended_at: Date | null;
    total_questions: number;
    correct_answers: number;
    created_at: string | Date | null;
    updated_at: string | Date | null;
    deleted_at: string | Date | null;
    questions: TrialExamQuestionType[];
    proctoring_id: string;
    proctoring?: ProctoringInfoType
}

export interface TheoryExamQuestionType {
    id: number;
    ru_title: string;
    kz_title: string;
    en_title: string | null;
    theory_exam_id: number;
    task_file_id: string;
    task_file_size: number;
    ru_question: string;
    kz_question: string;
    en_question: string;
    submitted_answer_id: number | null;
    answered_correct: boolean | null;
    created_at: string | Date | null;
    updated_at: string | Date | null;
    deleted_at: string | Date | null;
    answers: TheoryExamAnswerType[];
    order: number;
}

export interface TrialExamQuestionType extends TheoryExamQuestionType {}

export interface TheoryExamAnswerType {
    id: number;
    ru_text: string;
    kz_text: string
    en_text: string | null;
    question_id: number;
    is_correct?: boolean;
}

export interface TrialExamAnswerType extends TheoryExamAnswerType {}

export enum TheoryExamStatus {
    Created = 1,
    InProgress = 2,
    Finished = 3,
    Denied = 10
}

export enum TrialExamStatus {
    Created = 1,
    InProgress = 2,
    Finished = 3,
    Denied = 10
}

export interface ProctoringInfoType {
    errors: ProctoringErrorInfoType[],
    file_url?: string | null,
}

export interface ProctoringErrorInfoType {
    id: string;
    error: string;
    dateCreate: string | Date;
    fileName?: string;
    photoLink?: string;
}

export type ProductType = 'theory_exams' | 'trial_exams' | '';

export interface PurchaseType {
    recipient_id: number | null;
    school_id?: number | null;
    type: ProductType;
    amount: number;
    price: number;
    group_id?: string;
}

export enum TransactionStatus {
    Created = 1,
    Paid = 2,
    Denied = 3,
    Cancelled = 10
}

export enum TransactionChipStatus {
    'Не оплачено' = 1,
    'Оплачено' = 2,
    'Платеж отклонен' = 3,
    'Отмена' = 10
}

export interface TransactionType {
    id: number;
    type: string;
    status: TransactionStatus;
    amount: number;
    price: number;
    currency: string;
    payment_url: string;
    school_id?: number | null;
    user_id: number;
    user?: UserInfoType;
    school?: SchoolInfoType;
    recipient?: UserInfoType;
    recipient_id: number;
    invoice_id: string;
    created_at: string | Date;
    updated_at: string | Date;
    deleted_at: string | Date | null;
}

export interface FeatureToggleType {
    id: number;
    module_name: string;
    is_enabled: boolean;
    description?: string | null;
}

export interface NewsInfoType {
    id: number;
    title_ru: string;
    title_kz: string;
    description_ru: string;
    description_kz: string;
    content_ru: string;
    content_kz: string;
    thumbnail: string;
    created_at: Date | string;
    updated_at: Date | string;
}

export interface StandaloneNewsInfoType extends NewsInfoType {
    previous?: NewsInfoType;
    next?: NewsInfoType;
}