import React, { useState} from 'react';
import {Alert, Avatar, Box, Button, Chip, Stack, Tab, Tabs, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridRowModel} from "@mui/x-data-grid";
import {formatDate, getName} from "../../global/helpers/text";
import {EmployeeType} from "../actions/api";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CustomDataGrid from "../../global/components/CustomDataGrid";
import {useUserStore} from "../../global/store/UserStore";
import {toast} from "react-toastify";

interface EmployeesTableProps {
    rows: any[];
    loading: boolean;
    fetchEmployees: () => void,
    employee: EmployeeType
}

const NoRows = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
            <Typography>
                Нет данных
            </Typography>
        </Box>
    )
}

const EmployeesTable = ({rows, loading, fetchEmployees, employee} : EmployeesTableProps) => {

    const {id} = useParams();

    const {t} = useTranslation();

    const navigate = useNavigate();
    const userStore = useUserStore((state) => state);

    const [status, setStatus] = useState<number>(1);

    const columns: GridColDef[] = [
        {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'fio', headerName: t('school_page.employees_page.employee_grid.fio'), minWidth: 360, sortable: true,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'created_at',
            headerName: t('school_page.employees_page.employee_grid.created_at'),
            minWidth: 150,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'job',
            headerName: t('school_page.employees_page.employee_grid.job'),
            width: employee === 'teachers' ? 150 : employee === 'drivers' ? 250 : 300,
            renderCell: (() => getEmployeeChip(employee))
        },
        {
            field: 'status',
            headerName: t('school_page.employees_page.employee_grid.status.text'),
            minWidth: 160,
            renderCell: ({value}) => value === 1 ? <Chip color={'primary'} label={t('school_page.employees_page.employee_grid.status.active')}/> : <Chip color={'default'} label={t('school_page.employees_page.employee_grid.status.ianctive')}/>
        },
        {
            field: 'commentary',
            headerName: 'Примечание',
            width: 700,
            renderCell: ({row}) => {
                if (!isEmployeeFullInfo(row)) {
                    return (
                        <Alert
                            severity={'error'}
                            onClick={() => {userStore.isObserver ? toast.error('Обновление информации о сотруднике доступно только для менеджеров автошколы') : navigate(`/schools/${id}/employees/${row.id}?type=${employee}`)}}
                            sx={{cursor: 'pointer'}}
                        >
                            {t('school_page.employees_page.employee_grid.update_info')}
                        </Alert>
                    )
                } else {
                    return (
                      <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                      >
                          <Alert>
                              Вся информация заполнена
                          </Alert>
                          <Button
                            variant={'outlined'}
                            onClick={() => {userStore.isObserver ? toast.error('Обновление информации о сотруднике доступно только для менеджеров автошколы') : navigate(`/schools/${id}/employees/${row.id}?type=${employee}`)}}
                            sx={{
                                height: 'auto'
                            }}
                          >
                              Обновить данные
                          </Button>
                      </Stack>
                    )
                }
            }
        }
    ];

    const getEmployeeChip = (employee: EmployeeType) => {
        if (employee === 'teachers') {
            return <Chip color={'default' } label={t('school_page.employees_page.employee_grid.chip.teacher')}/>
        } else if (employee === 'drivers') {
            return <Chip color={'default'} label={t('school_page.employees_page.employee_grid.chip.driver')}/>
        } else if (employee === 'masters') {
            return <Chip color={'default'} label={t('school_page.employees_page.employee_grid.chip.master')}/>
        }
    }

    const isEmployeeFullInfo = (row: GridRowModel<any>) => {
        if (!row.qualification_certificate || !row.iin
            || !row.driver_license_series || !row.driver_license_number || !row.driver_license_date_of_issue || !row.driver_license_expires_date
            || !row.driver_license_categories || !row.qualification_certificate || !row.qualification_certificate_issuer || !row.qualification_certificate_issue_date || !row.qualification_certificate_end_date) {
            return false;
        } else {
            return true;
        }
    }

    const filterRows = () => {
        return rows.filter((row) => {
            return row.status === status;
        })
    }

    const tabs = {
        active: {id: 1, label: `Активные`},
        inactive: {id: 2, label: `Архив`},
    };

    return (
        <Box>
            <Tabs
                value={status}
                onChange={(e, newValue) => setStatus(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{mt: 2, ml: 2}}
            >
                {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
            </Tabs>

            <CustomDataGrid
                columns={columns}
                rows={filterRows()}
                loading={loading}
                autoHeight={true}
                pageSizeOptions={[20]}
                disableRowSelectionOnClick={true}
                style={{margin: '0'}}
            />
        </Box>
    );
};

export default EmployeesTable;