import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle, Divider,
  Stack,
  Typography
} from '@mui/material';
import useLoadingHook from '../../../global/hooks/UseLoadingHook';
import {
  apiAutoFillClasses,
  getPracticeHoursByGroupId,
  getTheoryHoursByGroupId,
} from '../../actions/api';
import LoadingButton from '../../../global/components/LoadingButton';
import {toast} from "react-toastify";

interface AutoFillClassesModalProps {
  groupInfo: any,
  open: boolean,
  setOpen: (st: boolean) => void,
  fetchGroupInfo: () => void,
  fetchClasses: (id: number) => void
}

const AutoFillClassesModal = ({groupInfo, open, setOpen, fetchGroupInfo, fetchClasses} : AutoFillClassesModalProps) => {

  const [theoryList, theoryLoading, , fetchTheory] = useLoadingHook(getTheoryHoursByGroupId);
  const [practiceList, practiceLoading, , fetchPractice] = useLoadingHook(getPracticeHoursByGroupId);

  const [autoFillResult, autoFillLoading, autoFillError, autoFill] = useLoadingHook(apiAutoFillClasses);

  const [autoFillDto, setAutoFillDto] = useState<any>({
    school_id: groupInfo.school_id,
    group_id: groupInfo.id,
    type: 'theory',
    student_id: null,
    section_id: null
  });

  useEffect(() => {
    fetchTheory(Number(groupInfo.id));
    fetchPractice(Number(groupInfo.id));
  }, []);

  useEffect(() => {
    if (autoFillResult) {
      updateClasses();
    }
  }, [autoFillResult]);

  useEffect(() => {
    if (autoFillError) {
      toast.error(autoFillError?.response?.data?.message ? autoFillError?.response?.data?.message : 'Непредвиденная ошибка')
    }
  }, [autoFillError]);

  const updateClasses = async () => {
    toast.success('Занятие успешно выставлено');
    await fetchGroupInfo();
    fetchClasses(Number(groupInfo.id));
    setOpen(false);
  };

  const handleAutoFill = (type: string, sectionId: string | number, studentId: string | number | null) => {
    autoFill({
      school_id: groupInfo.school_id,
      group_id: groupInfo.id,
      type: type,
      student_id: studentId,
      section_id: sectionId
    })
  }

  return (
    <Dialog
      open={open}
      onClose={() => {setOpen(false)}}
      maxWidth={'xl'}
      fullWidth={true}
    >
      <DialogTitle>
        Авто-заполнение занятий
      </DialogTitle>
      <DialogContent>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {(theoryLoading || practiceLoading) && (
            <CircularProgress />
          )}

          <Typography
            variant={'h6'}
          >
            Теоретические занятия:
          </Typography>

          <Stack
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
          >
            {theoryList?.map((theory: any) => {
              return (
                <Stack
                  flexDirection={'column'}
                  sx={{
                    background: '#f5f5f5',
                    p: 2,
                    borderRadius: '16px',
                    width: 'fit-content'
                  }}
                  key={theory.id}
                  gap={2}
                >
                  <Stack
                    flexDirection={'row'}
                    gap={1}
                  >
                    <Typography
                      fontWeight={'bold'}
                      sx={{
                        width: '150px'
                      }}
                    >
                      Предмет:
                    </Typography>
                    <Typography
                      sx={{
                        width: '300px'
                      }}
                    >
                      {theory.section.title_ru}
                    </Typography>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    gap={1}
                  >
                    <Typography
                      fontWeight={'bold'}
                      sx={{
                        width: '150px'
                      }}
                    >
                      Часы:
                    </Typography>
                    <Typography
                      sx={{
                        width: '300px'
                      }}
                    >
                      {theory.current_hours}/{theory.max_hours} академических часов
                    </Typography>
                  </Stack>

                  {(theory.current_hours !== theory.max_hours) ? (
                    <LoadingButton
                      loading={autoFillLoading}
                      variant={'contained'}
                      sx={{
                        mt: 'auto'
                      }}
                      onClick={() => {handleAutoFill('theory', theory.section.id, null)}}
                    >
                      Запустить авто-заполнение по данному предмету
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      loading={autoFillLoading}
                      variant={'contained'}
                      sx={{
                        mt: 'auto'
                      }}
                      disabled={true}
                    >
                      Занятия запланированы по всем темам
                    </LoadingButton>
                  )}
                </Stack>
              )
            })}
          </Stack>

          <Divider />

          <Typography
            variant={'h6'}
          >
            Практические занятия:
          </Typography>

          <Stack
            flexDirection={'row'}
            gap={1}
            flexWrap={'wrap'}
          >
            {practiceList?.map((practice: any) => {
              return (
                <Stack
                  flexDirection={'column'}
                  sx={{
                    background: '#f5f5f5',
                    p: 2,
                    borderRadius: '16px',
                    width: 'fit-content',
                  }}
                  key={practice.id}
                  gap={2}
                >
                  <Stack
                    flexDirection={'row'}
                    gap={1}
                    flexWrap={'wrap'}
                  >
                    <Typography
                      fontWeight={'bold'}
                      sx={{
                        width: '150px'
                      }}
                    >
                      Студент:
                    </Typography>
                    <Typography
                      sx={{
                        width: '300px'
                      }}
                    >
                      {practice.student.last_name} {practice.student.first_name}
                    </Typography>
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    gap={1}
                  >
                    <Typography
                      fontWeight={'bold'}
                      sx={{
                        width: '150px'
                      }}
                    >
                      Часы:
                    </Typography>
                    <Typography
                      sx={{
                        width: '300px'
                      }}
                    >
                      {practice.current_hours}/{practice.max_hours} часов
                    </Typography>
                  </Stack>

                  {(practice.current_hours !== practice.max_hours) ? (
                    <LoadingButton
                      loading={autoFillLoading}
                      variant={'contained'}
                      sx={{
                        mt: 'auto'
                      }}
                      onClick={() => {handleAutoFill('practice', practice.section_id, practice.student_id)}}
                    >
                      Запустить авто-заполнение по данному студенту
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      loading={autoFillLoading}
                      variant={'contained'}
                      sx={{
                        mt: 'auto'
                      }}
                      disabled={true}
                    >
                      Все занятия запланированы
                    </LoadingButton>
                  )}
                </Stack>
              )
            })}
          </Stack>

        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AutoFillClassesModal;
