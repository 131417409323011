import React from 'react';
import {Box, Button, Stack, Typography, useTheme} from '@mui/material';
import {Link} from "react-router-dom";
import {useUserStore} from "../store/UserStore";
import KursantIcon from '../assets/images/KursantIcon';
import { logoutFromSystem } from '../actions/main.api';
import MiniNotifications from '../../Notifications/pages/MiniNotifications';
import LanguageSelector from "./LanguageSelector";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import {ExitToApp} from "@mui/icons-material";

const linkStyle = {
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        color: 'blue'
    }
}

const linkTypoStyle = {
    p: '3px 16px',
    fontWeight: '500',
    '&:hover': {
        color: '#044EBD'
    }
}

const headerSx = {
    background: 'transparent',
    display: 'flex',
    width: '100%',
    minHeight: '87px',
    pl: 5,
    pr: 5
}

interface PageHeaderProps {
  languageSelector?: boolean;
  authBar?: boolean;
}

const GuestBar = () => {
  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{background: 'transparent', py: 2, px: 5, width: '100%'}}
    >
        <KursantIcon
          lightTheme={true}
        />

        <LanguageSelector
          reverseGamma={true}
        />
    </Stack>
  )
}

const AuthBar = () => {
  return (
    <Stack
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{background: 'transparent', py: 2, px: 5}}
      gap={'16px'}
    >
      <KursantIcon
        lightTheme={true}
      />

      <LanguageSelector
        reverseGamma={true}
      />
    </Stack>
  )
}

const ManagerBar = () => {

    const {t} = useTranslation();

    return (
      <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{...headerSx}}>
          <KursantIcon
            lightTheme={true}
          />

          <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={'16px'} sx={{ml: '150px'}}>
            <Link to={'/'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.app.main')}</Typography></Link>

            <Link to={'/schools'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('school_page.employees_page.schools_list_page')}</Typography></Link>

            <Link to={'/tickets'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.new_ticket_page.path.support')}</Typography></Link>

            <Link to={'/journal'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.app.journal')}</Typography></Link>
          </Stack>

          <Stack flexDirection={'row'} gap={2} sx={{ml: 'auto'}} alignItems={'center'}>
              <MiniNotifications />

              <LanguageSelector
                reverseGamma={true}
              />

              <Button
                variant={'outlined'}
                startIcon={
                  <ExitToApp/>
                }
                sx={{
                  marginLeft: 'auto',
                  p: '0px 24px',
                  color: '#05304F',
                  px: '29px',
                  textTransform: 'none'
                }}
                onClick={() => {logoutFromSystem()}}
              >
                {t('auth.auth-page.logout-btn')}
              </Button>
          </Stack>
      </Stack>
    )
}

const StudentBar = () => {

  const {t} = useTranslation();

  return (
      <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{...headerSx}}>
          <KursantIcon
            lightTheme={true}
          />

          <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={'16px'} sx={{ml: '54px'}}>
            <Link to={'/tickets'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.app.support')}</Typography></Link>

            <Link to={'/journal'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.app.journal')}</Typography></Link>
          </Stack>

          <Stack flexDirection={'row'} gap={2} sx={{ml: 'auto'}} alignItems={'center'}>
              <MiniNotifications />

              <LanguageSelector
                reverseGamma={true}
              />

            <Button
              variant={'outlined'}
              startIcon={
                <ExitToApp/>
              }
              sx={{
                marginLeft: 'auto',
                p: '0px 24px',
                color: '#05304F',
                px: '29px',
                textTransform: 'none'
              }}
              onClick={() => {logoutFromSystem()}}
            >
              {t('auth.auth-page.logout-btn')}
            </Button>
          </Stack>
      </Stack>
    )
}

const AdminBar = () => {

    const {t} = useTranslation();

    return (
        <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{...headerSx}}>
          <KursantIcon
            lightTheme={true}
          />

          <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={'16px'} sx={{ml: '54px'}}>
              <Link to={'/admin'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.app.appeals')}</Typography></Link>

            <Link to={'/schools'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('school_page.employees_page.schools_list_page')}</Typography></Link>

              <Link to={'/admin/tickets'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.app.support')}</Typography></Link>

              <Link to={'/journal'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.app.journal')}</Typography></Link>
          </Stack>

          <Stack flexDirection={'row'} gap={2} sx={{ml: 'auto'}} alignItems={'center'}>
              <MiniNotifications />

              <LanguageSelector
                reverseGamma={true}
              />

              <Button
                  variant={'outlined'}
                  onClick={() => {logoutFromSystem()}}
                  startIcon={
                    <ExitToApp/>
                  }
                  sx={{
                    marginLeft: 'auto',
                    p: '0px 24px',
                    color: '#05304F',
                    px: '29px',
                    textTransform: 'none'
                  }}
              >
                  Выйти из аккаунта
              </Button>
          </Stack>
        </Stack>
    )
}

const ObserverBar = () => {

  const {t} = useTranslation();

  return (
    <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{...headerSx}}>
      <KursantIcon
        lightTheme={true}
      />

      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={'16px'} sx={{ml: '54px'}}>
        <Link to={'/admin'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('register.app.appeals')}</Typography></Link>

        <Link to={'/schools'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>{t('school_page.employees_page.schools_list_page')}</Typography></Link>
      </Stack>

      <Stack flexDirection={'row'} gap={2} sx={{ml: 'auto'}} alignItems={'center'}>
        <MiniNotifications />

        <LanguageSelector
          reverseGamma={true}
        />

        <Button
          variant={'outlined'}
          onClick={() => {logoutFromSystem()}}
          startIcon={
            <ExitToApp/>
          }
          sx={{
            marginLeft: 'auto',
            p: '0px 24px',
            color: '#05304F',
            px: '29px',
            textTransform: 'none'
          }}
        >
          Выйти из аккаунта
        </Button>
      </Stack>
    </Stack>
  )
}

const PageHeader = ({languageSelector = true, authBar = false} : PageHeaderProps) => {

  const currentUser = useUserStore((state) => state);

  const theme = useTheme();

  const {t} = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  if (isMobile) {
    return (
      <Stack
        flexDirection={'column'}
        sx={{
          width: '100%',
          px: 1,
          py: 1
        }}
        gap={'10px'}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            width: '100%'
          }}
        >
          <KursantIcon
            width={'200px'}
            height={'auto'}
            lightTheme={true}
          />

          {currentUser.iin && <Stack
            flexDirection={'row'}
            gap={'10px'}
            alignItems={'center'}
          >
            <Button
              variant={'outlined'}
              onClick={() => {
                logoutFromSystem()
              }}
              startIcon={
                <ExitToApp/>
              }
              sx={{
                color: '#05304F',
                px: '20px',
                py: '10px',
                height: 'auto',
                textTransform: 'none'
              }}
            >
              {t('auth.auth-page.logout-btn')}
            </Button>
          </Stack>}
        </Stack>

        <Stack
          flexDirection={'row'}
          sx={{
            width: '100%'
          }}
          justifyContent={'flex-end'}
        >
          <LanguageSelector
            reverseGamma={true}
          />
        </Stack>
      </Stack>
    )
  }

  return (
      <Stack
          direction={'row'}
          justifyContent={'center'}
          sx={{
            width: '100%',
            py: 1
          }}
      >
        {currentUser.iin && !currentUser.isAdmin && !currentUser.isStudent && !currentUser.isObserver && <ManagerBar />}
        {currentUser.iin && !currentUser.isAdmin && currentUser.isStudent && <StudentBar />}
        {currentUser.iin && !currentUser.isAdmin && !currentUser.isStudent && currentUser.isObserver && <ObserverBar />}
        {currentUser.iin && currentUser.isAdmin && !currentUser.isObserver && <AdminBar />}
        {!currentUser.iin && !authBar && <GuestBar />}
        {!currentUser.iin && authBar && <AuthBar />}
      </Stack>
  );
};

export default PageHeader;