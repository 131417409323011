import React, { useState } from 'react';
import {
    Stack,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
} from '@mui/material';
import {IRegisterAttributes, IRegisterSchool, RegisterEmployeeDto, RegisterTransportDto} from '../dto/AuthDto';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

interface SecondStepProps {
    schoolForm: IRegisterSchool;
    attributesForm: IRegisterAttributes;
    setAttributesForm: (form: IRegisterAttributes) => void;
    nextStep: (step: number) => void;
}

const SecondStep: React.FC<SecondStepProps> = ({ schoolForm, attributesForm, setAttributesForm, nextStep }) => {
    const [teachers, setTeachers] = useState<RegisterEmployeeDto[]>(attributesForm.teachers || []);
    const [drivers, setDrivers] = useState<RegisterEmployeeDto[]>(attributesForm.drivers || []);
    const [masters, setMasters] = useState<RegisterEmployeeDto[]>(attributesForm.masters || []);
    const [transports, setTransports] = useState<RegisterTransportDto[]>(attributesForm.transports || []);

    const { t } = useTranslation();

    const updateAttributesForm = (type: 'teachers' | 'drivers' | 'masters' | 'transports', updatedData: any[]) => {
        setAttributesForm({
            ...attributesForm,
            [type]: updatedData,
        });
    };

    const handleAddForm = (type: 'teachers' | 'drivers' | 'masters' | 'transports') => {
        const newItem = { iin: '', qualification_certificate: '', qualification_certificate_issuer: '', qualification_certificate_issue_date: '', qualification_certificate_end_date: ''};
        const newTransport = { plate_number: '', description: '' };

        switch (type) {
            case 'teachers':
                setTeachers([...teachers, newItem]);
                updateAttributesForm('teachers', [...teachers, newItem]);
                break;
            case 'drivers':
                setDrivers([...drivers, newItem]);
                updateAttributesForm('drivers', [...drivers, newItem]);
                break;
            case 'masters':
                setMasters([...masters, newItem]);
                updateAttributesForm('masters', [...masters, newItem]);
                break;
            case 'transports':
                setTransports([...transports, newTransport]);
                updateAttributesForm('transports', [...transports, newTransport]);
                break;
        }
    };

    const handleRemoveForm = (index: number, type: 'teachers' | 'drivers' | 'masters' | 'transports') => {
        const removeForm = (arr: any[], setArr: React.Dispatch<React.SetStateAction<any[]>>, fieldType: 'teachers' | 'drivers' | 'masters' | 'transports') => {
            const newArray = arr.filter((_, i) => i !== index);
            setArr(newArray);
            updateAttributesForm(fieldType, newArray);
        };

        switch (type) {
            case 'teachers':
                removeForm(teachers, setTeachers, 'teachers');
                break;
            case 'drivers':
                removeForm(drivers, setDrivers, 'drivers');
                break;
            case 'masters':
                removeForm(masters, setMasters, 'masters');
                break;
            case 'transports':
                removeForm(transports, setTransports, 'transports');
                break;
        }
    };

    const handleInputChange = (index: number, type: 'teachers' | 'drivers' | 'masters' | 'transports', field: string, value: string) => {
        const updateForm = (arr: any[], setArr: React.Dispatch<React.SetStateAction<any[]>>, fieldType: 'teachers' | 'drivers' | 'masters' | 'transports') => {
            const newArray = [...arr];
            newArray[index] = { ...newArray[index], [field]: value };
            setArr(newArray);
            updateAttributesForm(fieldType, newArray);
        };

        switch (type) {
            case 'teachers':
                updateForm(teachers, setTeachers, 'teachers');
                break;
            case 'drivers':
                updateForm(drivers, setDrivers, 'drivers');
                break;
            case 'masters':
                updateForm(masters, setMasters, 'masters');
                break;
            case 'transports':
                updateForm(transports, setTransports, 'transports');
                break;
        }
    };

    const handleSelectChange = (index: number, value: string) => {
        const newTransports = [...transports];
        newTransports[index].description = value;
        setTransports(newTransports);
        updateAttributesForm('transports', newTransports);
    };

    const validateForms = () => {
        if (teachers.length === 0) {
            toast.error('Необходимо добавить не менее 1 преподавателя');
            return false
        }

        if (drivers.length === 0) {
            toast.error('Необходимо добавить не менее 1 мастера обучения вождению');
            return false
        }

        if (transports.length === 0) {
            toast.error('Необходимо добавить не менее 1 учебного транспортного средства');
            return false
        }

        for (let teacher of teachers) {
            if (!teacher.qualification_certificate || !teacher.qualification_certificate_issue_date || !teacher.qualification_certificate_end_date || !teacher.qualification_certificate_issuer) {
                toast.error('Необходимо добавить все данные сотрудника (квалификационное свидетельство и детали) - преподавателя');
                return false
            }
        }

        for (let driver of drivers) {
            if (!driver.qualification_certificate || !driver.qualification_certificate_issue_date || !driver.qualification_certificate_end_date || !driver.qualification_certificate_issuer) {
                toast.error('Необходимо добавить все данные сотрудника (квалификационное свидетельство и детали) - мастера обучения вождению');
                return false
            }
        }

        if (!schoolForm.is_gov) {
            if (masters.length === 0) {
                toast.error('Необходимо добавить не менее 1 мастера производственного обучения');
                return false
            }

            for (let master of masters) {
                if (!master.qualification_certificate || !master.qualification_certificate_issue_date || !master.qualification_certificate_end_date || !master.qualification_certificate_issuer) {
                    toast.error('Необходимо добавить все данные сотрудника (квалификационное свидетельство и детали) - мастера производственного обучения');
                    return false
                }
            }
        }

        return true;
    }

    const handleSubmit = () => {

        const formsValidated = validateForms();

        if (!formsValidated) {
            return;
        }

        setAttributesForm({
            teachers,
            drivers,
            masters,
            transports,
        });
        nextStep(2);
    };

    return (
        <Stack sx={{p: '36px', background: '#FFF'}} gap={'36px'}>
            {/* Teachers Section */}
            <Stack spacing={2}>
                <Typography className={'info-header'}><b>{t('auth.second-step.teachers-info')}</b></Typography>
                {teachers.map((teacher, index) => (
                    <Stack key={index} direction="column" spacing={2}>
                        <TextField
                            label={t('auth.second-step.iin')}
                            value={teacher.iin}
                            onChange={(e) => handleInputChange(index, 'teachers', 'iin', e.target.value)}
                            fullWidth
                        />
                        <TextField
                            label={t('auth.second-step.qualification_certificate')}
                            value={teacher.qualification_certificate}
                            onChange={(e) => handleInputChange(index, 'teachers', 'qualification_certificate', e.target.value)}
                            fullWidth
                        />
                        <TextField
                          type={'text'}
                          placeholder={t('auth.second-step.qualification_certificate_issuer')}
                          value={teacher.qualification_certificate_issuer}
                          name={'qualification_certificate_issuer'}
                          onChange={(e) => handleInputChange(index, 'teachers', 'qualification_certificate_issuer', e.target.value)}
                          required={true}
                        />

                        <TextField
                          name={'qualification_certificate_issue_date'}
                          type={'date'}
                          value={teacher.qualification_certificate_issue_date}
                          onChange={(e) => handleInputChange(index, 'teachers', 'qualification_certificate_issue_date', e.target.value)}
                          label={t('auth.second-step.qualification_certificate_issue_date')}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                  border: "1px solid #99999F"
                              }
                          }}
                        />

                        <TextField
                          name={'qualification_certificate_end_date'}
                          type={'date'}
                          value={teacher.qualification_certificate_end_date}
                          onChange={(e) => handleInputChange(index, 'teachers', 'qualification_certificate_end_date', e.target.value)}
                          label={t('auth.second-step.qualification_certificate_end_date')}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                  border: "1px solid #99999F"
                              }
                          }}
                        />
                        <Button variant="outlined" color="error" onClick={() => handleRemoveForm(index, 'teachers')}>
                            {t('auth.second-step.delete')}
                        </Button>
                    </Stack>
                ))}
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button variant="contained" onClick={() => handleAddForm('teachers')} sx={{ p: 1 }}>
                        {t('auth.second-step.add-teacher')}
                    </Button>
                </Stack>
            </Stack>

            {/* Drivers Section */}
            <Stack spacing={2}>
                <Typography className={'info-header'}><b>{t('auth.second-step.drivers-info')}</b></Typography>
                {drivers.map((driver, index) => (
                    <Stack key={index} direction="column" spacing={2}>
                        <TextField
                            label={t('auth.second-step.iin')}
                            value={driver.iin}
                            onChange={(e) => handleInputChange(index, 'drivers', 'iin', e.target.value)}
                            fullWidth
                        />
                        <TextField
                            label={t('auth.second-step.qualification_certificate')}
                            value={driver.qualification_certificate}
                            onChange={(e) => handleInputChange(index, 'drivers', 'qualification_certificate', e.target.value)}
                            fullWidth
                        />
                        <TextField
                          type={'text'}
                          placeholder={t('auth.second-step.qualification_certificate_issuer')}
                          value={driver.qualification_certificate_issuer}
                          name={'qualification_certificate_issuer'}
                          onChange={(e) => handleInputChange(index, 'drivers', 'qualification_certificate_issuer', e.target.value)}
                          required={true}
                        />
                        <TextField
                          name={'qualification_certificate_issue_date'}
                          type={'date'}
                          value={driver.qualification_certificate_issue_date}
                          onChange={(e) => handleInputChange(index, 'drivers', 'qualification_certificate_issue_date', e.target.value)}
                          label={t('auth.second-step.qualification_certificate_issue_date')}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                  border: "1px solid #99999F"
                              }
                          }}
                        />
                        <TextField
                          name={'qualification_certificate_end_date'}
                          type={'date'}
                          value={driver.qualification_certificate_end_date}
                          onChange={(e) => handleInputChange(index, 'drivers', 'qualification_certificate_end_date', e.target.value)}
                          label={t('auth.second-step.qualification_certificate_end_date')}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                  border: "1px solid #99999F"
                              }
                          }}
                        />
                        <Button variant="outlined" color="error" onClick={() => handleRemoveForm(index, 'drivers')}>
                            {t('auth.second-step.delete')}
                        </Button>
                    </Stack>
                ))}
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button variant="contained" onClick={() => handleAddForm('drivers')} sx={{ p: 1 }}>
                        {t('auth.second-step.add-driver')}
                    </Button>
                </Stack>
            </Stack>

            {/* Masters Section */}
            {!schoolForm?.is_gov && (<Stack spacing={2}>
                <Typography className={'info-header'}><b>{t('auth.second-step.masters-info')}</b></Typography>
                {masters.map((master, index) => (
                  <Stack key={index} direction="column" spacing={2}>
                      <TextField
                        label={t('auth.second-step.iin')}
                        value={master.iin}
                        onChange={(e) => handleInputChange(index, 'masters', 'iin', e.target.value)}
                        fullWidth
                      />
                      <TextField
                        label={t('auth.second-step.qualification_certificate')}
                        value={master.qualification_certificate}
                        onChange={(e) => handleInputChange(index, 'masters', 'qualification_certificate', e.target.value)}
                        fullWidth
                      />
                      <TextField
                        type={'text'}
                        placeholder={t('auth.second-step.qualification_certificate_issuer')}
                        value={master.qualification_certificate_issuer}
                        name={'qualification_certificate_issuer'}
                        onChange={(e) => handleInputChange(index, 'masters', 'qualification_certificate_issuer', e.target.value)}
                        required={true}
                      />
                      <TextField
                        name={'qualification_certificate_issue_date'}
                        type={'date'}
                        value={master.qualification_certificate_issue_date}
                        onChange={(e) => handleInputChange(index, 'masters', 'qualification_certificate_issue_date', e.target.value)}
                        label={t('auth.second-step.qualification_certificate_issue_date')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                border: "1px solid #99999F"
                            }
                        }}
                      />
                      <TextField
                        name={'qualification_certificate_end_date'}
                        type={'date'}
                        value={master.qualification_certificate_end_date}
                        onChange={(e) => handleInputChange(index, 'masters', 'qualification_certificate_end_date', e.target.value)}
                        label={t('auth.second-step.qualification_certificate_end_date')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                border: "1px solid #99999F"
                            }
                        }}
                      />
                      <Button variant="outlined" color="error" onClick={() => handleRemoveForm(index, 'masters')}>
                          {t('auth.second-step.delete')}
                      </Button>
                  </Stack>
                ))}
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button variant="contained" onClick={() => handleAddForm('masters')} sx={{p: 1}}>
                        {t('auth.second-step.add-master')}
                    </Button>
                </Stack>
            </Stack>)}

            {/* Transports Section */}
            <Stack spacing={2}>
                <Typography className={'info-header'}><b>{t('auth.second-step.transports-info')}</b></Typography>
                {transports.map((transport, index) => (
                    <Stack key={index} direction="column" spacing={2}>
                        <TextField
                            label={t('auth.second-step.plate_number')}
                            value={transport.plate_number}
                            onChange={(e) => handleInputChange(index, 'transports', 'plate_number', e.target.value)}
                            fullWidth
                        />
                        <FormControl fullWidth>
                            <InputLabel id={`description-label-${index}`}>{t('auth.second-step.description')}</InputLabel>
                            <Select
                                labelId={`description-label-${index}`}
                                value={transport.description || ''}
                                onChange={(e) => handleSelectChange(index, e.target.value)}
                                label={t('auth.second-step.description')}
                            >
                                <MenuItem value="АКПП">АКПП</MenuItem>
                                <MenuItem value="МКПП">МКПП</MenuItem>
                            </Select>
                        </FormControl>
                        <Button variant="outlined" color="error" onClick={() => handleRemoveForm(index, 'transports')}>
                            {t('auth.second-step.delete')}
                        </Button>
                    </Stack>
                ))}
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button variant="contained" onClick={() => handleAddForm('transports')} sx={{ p: 1 }}>
                        {t('auth.second-step.add-transport')}
                    </Button>
                </Stack>
            </Stack>

            {/* Navigation Buttons */}
            <Stack direction="row" justifyContent="center" spacing={4}>
                <Button
                    onClick={() => nextStep(0)}
                    color="info"
                    variant="outlined"
                    sx={{ width: '207px', height: '56px' }}
                >
                    {t('auth.second-step.back')}
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ width: '207px', height: '56px' }}
                >
                    {t('auth.second-step.next')}
                </Button>
            </Stack>
        </Stack>
    );
};

export default SecondStep;
