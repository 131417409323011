import {Avatar, Box, Stack, Tooltip, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import {formatDate} from "../../../global/helpers/text";
import {GroupStatusChip} from "../GroupsList/GroupStatusChip";
import {GroupDto, GroupStatus} from "../../actions/group.type";
import {useTranslation} from "react-i18next";

interface GroupHeaderProps {
    groupInfo: GroupDto;
}

export function GroupHeader({groupInfo} : GroupHeaderProps) {

    const {t} = useTranslation();

    const GroupStatusHelperText = <Box>
        <Typography variant="body2" sx={{mt: 1}}>
            <b>{t('school_page.group_page.gr_status_hds.part_one.created')}</b>{t('school_page.group_page.gr_status_hds.part_two.created')}
        </Typography>
        <Typography variant="body2" sx={{mt: 1}}>
            <b>{t('school_page.group_page.gr_status_hds.part_one.active')}</b>{t('school_page.group_page.gr_status_hds.part_two.active')}
        </Typography>
        <Typography variant="body2" sx={{mt: 1}}>
            <b>{t('school_page.group_page.gr_status_hds.part_one.exam')}</b>{t('school_page.group_page.gr_status_hds.part_two.exam')}
        </Typography>
        <Typography variant="body2" sx={{mt: 1}}>
            <b>{t('school_page.group_page.gr_status_hds.part_one.finished')}</b>{t('school_page.group_page.gr_status_hds.part_two.finished')}
        </Typography>
    </Box>;


    return (
        <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between" sx={{width: '100%', mb: 3}}
               flexWrap="wrap">
            <Stack flexDirection="row" alignItems="center" flexWrap="wrap" sx={{mt: 1}}>
                <Avatar
                    sx={{fontWeight: 500, mr: 2, color: '#99999F', bgcolor: '#D9E8FF', width: 50, height: 50}}
                >
                    {groupInfo.category_id}
                </Avatar>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">{t('school_page.group_page.gr_hds_stack.group')}</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{groupInfo.title}</Typography>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">ID</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{groupInfo.id}</Typography>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">ID учебного центра</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{groupInfo.school_id}</Typography>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">{t('school_page.group_page.gr_hds_stack.category')}</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{groupInfo.category_id}</Typography>
                </Stack>
                <Stack sx={{mr: 8}}>
                    <Typography variant="h6" color="gray">{t('school_page.group_page.gr_hds_stack.status')}</Typography>
                    <Tooltip title={GroupStatusHelperText} arrow>
                        <Typography variant="h5" sx={{
                            mt: 0.3, textDecoration: 'underline',
                            textDecorationStyle: 'dotted'
                        }}>
                            <GroupStatusChip status={groupInfo.status} variant="text"/>
                        </Typography>
                    </Tooltip>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">{t('school_page.group_page.gr_hds_stack.start_date')}</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{formatDate(groupInfo.start_date)}</Typography>
                </Stack>
                <Stack sx={{mr: 6}}>
                    <Typography variant="h6" color="gray">{t('school_page.group_page.gr_hds_stack.end_date')}</Typography>
                    <Typography variant="h5" sx={{mt: 0.3}}>{formatDate(groupInfo.end_date)}</Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}