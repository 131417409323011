import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Box,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useUserStore } from '../../global/store/UserStore';
import PageHeader from '../../global/components/PageHeader';
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {
  apiGetFeeStudent,
  apiGetProductPriceByProductId,
  apiRequestTransaction
} from "../actions/api";
import {wrapperSx} from "../../global/helpers/globalstyles";
import LoadingButton from "../../global/components/LoadingButton";
import VisaIcon from "../../global/assets/images/VisaIcon";
import MasterCardIcon from "../../global/assets/images/MasterCardIcon";
import {apiGetFeatureEnabled} from "../../global/actions/main.api";
import StudentGroupCard from "../../Main/components/StudentGroupCard";
import {StudentInfoType} from "../../global/actions/types.api";

const FeePage = () => {

  const navigate = useNavigate();
  const {studentId} = useParams();

  const snackbar = useSnackbarStore((state) => state);
  const currentUser = useUserStore((state) => state);

  const [attachmentModuleInfoResponse, attachmentModuleInfoLoading, attachmentModuleInfoError, requestAttachmentModuleInfo] = useLoadingHook(apiGetFeatureEnabled);
  const [studentInfoResponse, studentInfoLoading, studentInfoError, requestStudentInfo] = useLoadingHook<StudentInfoType>(apiGetFeeStudent);
  const [attachmentPriceResponse, attachmentPriceLoading, attachmentPriceError, requestAttachmentPrice] = useLoadingHook(apiGetProductPriceByProductId);
  const [iokaTransactionResponse, iokaTransactionLoading, iokaTransactionError, requestIokaTransaction] = useLoadingHook(apiRequestTransaction);

  useEffect(() => {
    requestAttachmentPrice('attachment');
    requestStudentInfo(studentId);
    requestAttachmentModuleInfo('paid_student_attachments');
  }, []);

  const handleIOKAClick = () => {
    if (currentUser.id && studentInfoResponse) {
      requestIokaTransaction({
        user_id: Number(currentUser.id),
        recipient_id: currentUser.id,
        school_id: null,
        type: 'payment_access',
        amount: 1,
        price: attachmentPriceResponse.price,
        group_id: studentInfoResponse?.group_id,
        payment_system: 'freedom'
      })
    } else {
      snackbar.errorMessage('Отсутствует currentUser.id || studentInfoResponse')
    }
  }

  useEffect(() => {
    if (iokaTransactionResponse) {
      // Переход на внешнюю ссылку
      if (iokaTransactionResponse.payment_url) {
        window.location.href = iokaTransactionResponse.payment_url;
      }
    }

    if (iokaTransactionError) {
      snackbar.errorMessage(iokaTransactionError?.response?.data?.message ?? 'Ошибка')
    }
  }, [iokaTransactionResponse, iokaTransactionError]);

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>

      <Box sx={{...wrapperSx}}>
        <Stack
            direction={'column'}
            width={'100%'}
            sx={{
              mt: '38px',
              p: '52px',
              borderRadius: '24px',
              background: '#F5F5F5',
              gap: '16px'
            }}
        >
          <Typography
            variant={'h6'}
          >
            Оплата пошлины за хранение учебных данных
          </Typography>

          {studentInfoResponse && <StudentGroupCard studentInfo={studentInfoResponse} handleStartExam={() => {
          }} feePage={true}/>}

          <Typography fontWeight={'bold'}>
            Стоимость пошлины - {attachmentPriceResponse?.price} ₸
          </Typography>

          <Divider />

          <Stack>
            <Typography>
              Сумма к оплате:
            </Typography>
            <Typography variant={'h6'}>
              {attachmentPriceResponse?.price} ₸
            </Typography>
          </Stack>

          <Divider />

          <Typography>
            Выберите способ оплаты:
          </Typography>

          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            gap={'16px'}
          >
            <LoadingButton
                variant={'contained'}
                sx={{
                  width: '300px',
                  gap: '16px'
                }}
                onClick={() => {handleIOKAClick()}}
                loading={studentInfoLoading || attachmentPriceLoading || iokaTransactionLoading}
            >
              <VisaIcon width={'50px'} height={'50px'}/>
              <MasterCardIcon width={'50px'} height={'50px'}/>
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default FeePage;
