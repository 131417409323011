import React from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPaginationModelSelector,
  GridColDef, GridRowsProp, GridRowParams, GridPaginationModel
} from '@mui/x-data-grid';
import NoRows from "./NoRows";
import {PaginationItem} from "@mui/lab";
import {Pagination} from "@mui/material";
import {PaginationArgs} from "../../Group/pages/GroupsList";

const CustomPagination = () => {
  const apiRef = useGridApiContext(); // Доступ к API DataGrid
  const pageCount = useGridSelector(apiRef, gridPageCountSelector); // Общее количество страниц

  const paginationModel = gridPaginationModelSelector(apiRef);
  const page = paginationModel.page;

  console.log(paginationModel)

  const rowCount = apiRef.current.getRowsCount();

  console.log(rowCount)

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    apiRef.current.setPage(value - 1); // Обновляем текущую страницу
  };

  const previousSVG = () => {
    return (
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="52" height="52" rx="16" fill="#F5F5F5"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M23.9141 32L17.9998 26L23.9141 20L26.4998 20L21.8283 25L33.4141 25L33.4141 27L21.8283 27L26.4998 32L23.9141 32Z"
              fill="#05304F"/>
      </svg>
    )
  }

  const nextSVG = () => {
    return (
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="52" y="52" width="52" height="52" rx="16" transform="rotate(-180 52 52)" fill="#F5F5F5"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M27.5 20L33.4142 26L27.5 32L24.9143 32L29.5858 27L18 27L18 25L29.5858 25L24.9143 20L27.5 20Z"
              fill="#05304F"/>
      </svg>
    )
  }

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1} // DataGrid использует 0-индексацию страниц, Pagination — 1-индексацию
      count={rowCount ? (Math.round(rowCount / paginationModel.pageSize)+1) : pageCount} // Количество страниц
      // @ts-ignore
      // renderItem={(props) => (
      //   props.type === 'page' ?
      //     props.selected ?
      //       (<svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      //         <rect width="15.36" height="6.4" rx="3.2" fill="#05304F"/>
      //       </svg>)
      //       : (<svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      //         <rect x="0.200195" width="6.4" height="6.4" rx="3.2" fill="#05304F" fill-opacity="0.2"/>
      //       </svg>) :
      //     < PaginationItem
      //       {...props}
      //     />
      // )}
      renderItem={(props) => {
        if (props.type === 'page' && props.selected) {
          return (<svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="15.36" height="6.4" rx="3.2" fill="#05304F"/>
          </svg>)
        } else if (props.type === 'page' && !props.selected) {
          return (
            (<svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.200195" width="6.4" height="6.4" rx="3.2" fill="#05304F" fill-opacity="0.2"/>
            </svg>)
          )
        } else {
          return <PaginationItem {...props} slots={{previous: previousSVG, next: nextSVG}} sx={{
            width: 'fit-content',
            height: 'fit-content',
            border: '0px',
            p: 0,
            borderRadius: '16px'
          }}/>
        }
      }}
      sx={{
        display: "flex",
        gap: '16px',
        '.MuiPagination-ul': {
          display: 'flex',
          flexDirection: 'row',
          '> li:first-of-type': {
            marginLeft: '40px',
            marginRight: '83px', // Применяем отступ к первому <li>
          },
          '> li:last-of-type': {
            marginLeft: '83px', // Применяем отступ к последнему <li>
          },
        }
      }}
      onChange={handlePageChange}
    />
  );
};

type PaginationProps = {
  page: number; // Текущая страница
  count: number; // Общее количество строк
  pageSize: number; // Количество строк на странице
};

type CustomDataGridProps = {
  columns: GridColDef[]; // Определение колонок DataGrid
  rows: GridRowsProp; // Строки данных
  loading: boolean; // Индикатор загрузки
  handleRowClick?: (row: GridRowParams) => void; // Обработчик клика по строке
  pagination?: PaginationArgs;
  pageSizeOptions?: number[]; // Опции для размера страницы
  noRowsOverlay?: React.ReactNode; // Компонент для отображения, если нет строк
  onPaginationModelChange?: (params: GridPaginationModel) => void; // Изменение модели пагинации
} & Omit<React.ComponentProps<typeof DataGrid>, 'columns' | 'rows'>; // Все остальные свойства DataGrid, кроме колонок и стро

const CustomDataGrid = ({
                          columns,
                          rows,
                          pagination,
                          loading,
                          handleRowClick,
                          pageSizeOptions = [20],
                          ...props
                        }: CustomDataGridProps) => {

  console.log(pagination)

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 20,
            page: pagination?.page ?? 0,
          },
        },
      }}
      paginationMode="client"
      rowCount={pagination?.count}
      onPaginationModelChange={(params) => {
        props.onPaginationModelChange && props.onPaginationModelChange(params);
      }}
      pageSizeOptions={pageSizeOptions}
      autoHeight={true}
      loading={loading}
      onRowClick={handleRowClick}
      disableRowSelectionOnClick={true}
      sx={{
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: '#1E9AF3', // Цвет фона заголовков
          color: '#fff', // Цвет текста заголовков
          fontWeight: 'bold',
          borderRadius: '10px', // Скругление только верхних углов
          borderBottom: 'none', // Убираем нижнюю рамку
          boxSizing: 'border-box !important', // Учитываем padding в ширине
        },
        '.MuiDataGrid-columnHeader': {
          border: 'none !important',
          padding: '0 !important'
        },
        '.MuiDataGrid-columnSeparator': {
          display: 'none', // Убираем разделители между колонками
        },
        '.MuiDataGrid-row': {
          backgroundColor: '#F5F5F5',
          borderTop: '1px solid #fff'
        },
        '.MuiDataGrid-row:hover': {
          backgroundColor: '#f0f0f0',
        },
        '.MuiDataGrid-cell': {
          padding: '0 !important'
        },
        '.MuiDataGrid-footerContainer': {
          marginTop: '21px',
          border: '0px',
          justifyContent: 'flex-start',
        },
        m: 0,
        mb: 2
      }}
      slots={{
        noRowsOverlay: NoRows,
        pagination: CustomPagination,
      }}
      {...props}
    />
  );
};

export default CustomDataGrid;
