import { BACKEND_URL } from "../../global/actions/apiVariables";
import axiosInstance from "../../global/actions/main.api";
import {EmployeeCreateDto, EmployeeUpdateDto} from "./employee.type";

export type EmployeeType = 'teachers' | 'drivers' | 'masters';

export function isEmployeeType(value: any): value is EmployeeType {
    return ['teachers', 'drivers', 'masters'].includes(value);
}

// По всем сотрудникам

export const getEmployeeInfo = (employeeId: number, schoolId: number, employeesType: string) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/employee/${employeeId}?type=${employeesType}&schoolId=${schoolId}`);
}

export const updateEmployeeInfo = (dto: EmployeeUpdateDto, schoolId: number, employeesType: string) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/change`, {
        ...dto,
        school_id: schoolId,
        type: employeesType,
    });
}

// Преподаватели
export const getSchoolTeachers = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/school/teacher/${id}`);
}

export const getActiveSchoolTeachers = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/school/teacher/active/${id}`);
}

export const addTeacherToSchool = (formData: EmployeeCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/create/teacher`, formData);
}

export const fireTeacher = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/fire/teacher`, {id});
}

// Мастера обучения вождению

export const getSchoolDrivers = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/school/driver/${id}`);
}

export const getActiveSchoolDrivers = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/school/driver/active/${id}`);
}

export const addDriverToSchool = (formData: EmployeeCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/create/driver`, formData);
}

export const fireDriver = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/fire/driver`, {id});
}

// Мастера производственного обучения

export const getSchoolMasters = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/school/master/${id}`);
}

export const getActiveSchoolMasters = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/school/master/active/${id}`);
}

export const addMasterToSchool = (formData: EmployeeCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/create/master`, formData);
}

export const fireMaster = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/employees/fire/master`, {id});
}
