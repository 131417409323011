import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate} from "react-router-dom";
import {getApprovedAppeals, getClosedAppeals, getCreatedAppeals, getDeniedAppeals} from "../actions/api";
import '../styles/AdminPage.scss';
import AppealsGrid from "../components/AppealsGrid";
import AppealModal from "../components/AppealModal";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";

export enum AppealStatus {
    Created = 1,
    Approved = 2,
    Closed = 3,
    Denied = 10
}

const AdminPage = () => {

    const navigate = useNavigate();

    const errorMessage = useSnackbarStore((state) => state.errorMessage);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const isObserver = useUserStore((state) => state.isObserver);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [appealId, setAppealId] = useState<number | null>(null);
    const [rows, setRows] = useState<any[]>([]);
    const [tab, setTab] = useState<AppealStatus>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [filteredRows, setFilteredRows] = useState<any[]>([]);

    useEffect(() => {
        if (!isAdmin && !isObserver) {
            errorMessage('У вас нет права доступа к данной странице');
            navigate('/')
        } else {
            updateAppeals(1);
        }
    }, []);


    useEffect(() => {
        if (loading === false) {
            applyFilter();
        }
    }, [filter, loading])

    const applyFilter = () => {
        if (!filter) {
            setFilteredRows(rows);
        } else {
            const lowerCaseFilter = filter.toLowerCase();
            const filteredRows = JSON.parse(JSON.stringify(rows)).filter((row: any) => {
                if (row.bin.includes(lowerCaseFilter)) {
                    return true;
                } else if (row.title.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else if (`${row.last_name} ${row.first_name}`.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else if (row.iin.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else {
                    return false;
                }
            });

            setFilteredRows(filteredRows);
        }
    }

    const updateAppeals = (newTab: number) => {
        if (!isAdmin && !isObserver) {
            errorMessage('У вас нет права доступа к данной странице');
            return;
        }

        setLoading(true);

        if (newTab === 1) {
            getCreatedAppeals()
                .then((res) => {
                    createRows(res.data);
                })
                .catch((e) => {
                    errorMessage(e.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }

        if (newTab === 2) {
            getApprovedAppeals()
                .then((res) => {
                    createRows(res.data);
                })
                .catch((e) => {
                    errorMessage(e.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }

        if (newTab === 3) {
            getClosedAppeals()
                .then((res) => {
                    createRows(res.data);
                })
                .catch((e) => {
                    errorMessage(e.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }

        if (newTab === 10) {
            getDeniedAppeals()
                .then((res) => {
                    createRows(res.data);
                })
                .catch((e) => {
                    errorMessage(e.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const openModal = (appealId: number) => {
        setAppealId(appealId);
        setModalOpen(true);
    }

    const changeTab = (newTab: number) => {
        setTab(newTab);
        updateAppeals(newTab);
    }

    const createRows = (data: any) => {
        // const resRows: any[] = data;
        const resRows: any[] = data.rows;
        const newRows: any[] = [];

        resRows.forEach((row) => {
            const newRow = {
                id: row.id,
                bin: row.school_info.bin,
                title: row.school_info.title,
                phone1: row.school_info.phone1,
                iin: row.user_info.iin,
                fio: row.user_info.last_name + ' ' + row.user_info.first_name + ' ' + row.user_info.patronymic,
                phone: row.user_info.phone
            };

            newRows.push(newRow);
        })

        setRows(newRows);
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                {(isAdmin || isObserver) && (
                    <AppealsGrid
                        openAppeal={openModal}
                        rows={filter ? filteredRows : rows}
                        setTab={changeTab}
                        filter={filter}
                        setFilter={setFilter}
                        tab={tab}
                        loading={loading}
                    />
                )}

                <AppealModal
                    modalOpen={modalOpen}
                    onClose={() => {setModalOpen(false)}}
                    appealId={appealId}
                    updateAppeals={updateAppeals}
                    tab={tab}
                />
            </Box>
        </Box>
    );
};

export default AdminPage;