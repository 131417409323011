import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box, Checkbox,
  Stack, TextField,
  Typography
} from '@mui/material';
import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from "../../global/helpers/globalstyles";
import LoadingButton from "../../global/components/LoadingButton";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiGetStudentsFromAis, InfoByIinType} from "../actions/api";
import {StudentInfoType} from "../../global/actions/types.api";
import StudentGroupCard from "../../Main/components/StudentGroupCard";
import {toast} from "react-toastify";

const FeePage = () => {

  const navigate = useNavigate();

  const [iin, setIin] = useState<string>('');
  const [idNumber, setIdNumber] = useState<string>('');
  const [issueDate, setIssueDate] = useState<string>('');
  const [agreed, setAgreed] = useState<boolean>(false);

  const [studentsByIIN, studentsByIINLoading, studentsByIINError, fetchStudentsByIIN, resetStudentsByIIN] = useLoadingHook<InfoByIinType>(apiGetStudentsFromAis);

  const handleIinChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIin(e.currentTarget.value);
  }

  const handleFetchStudents = () => {

    if (!idNumber || !issueDate) {
      toast.error('Необходимо внести данные номера и даты выдачи удостоверения личности');
      return;
    }

    if (idNumber.length < 9) {
      toast.error('Номер удостоверения личности состоит из 9 цифр');
      return;
    }

    if (!agreed) {
      toast.error('Необходимо подтвердить согласие лица, в отношении которого ведется поиск учебных данных')
      return;
    }

    // TEMP
    toast.error('Идут технические работы. Примерная дата и время восстановления работы сервиса - 27.01.2025, 15:00');
    return;

    fetchStudentsByIIN(iin)
  }

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>

      <Box sx={{...wrapperSx}}>
        <Stack
          direction={'column'}
          width={'100%'}
          sx={{
            mt: '38px',
            p: '52px',
            borderRadius: '24px',
            background: '#F5F5F5',
            gap: '16px'
          }}
        >
          <Typography
            variant={'h6'}
          >
            Проверка данных об обучении
          </Typography>

          <Stack
            flexDirection={'column'}
            gap={2}
          >
            <TextField
              label={'ИИН'}
              name={'iin'}
              value={iin}
              sx={{
                width: '300px',
              }}
              onChange={handleIinChange}
            />

            <TextField
              label={'Номер удостоверения'}
              name={'id_number'}
              value={idNumber}
              sx={{
                width: '300px',
              }}
              onChange={(e) => {setIdNumber(e.target.value)}}
            />

            <TextField
              label={'Дата выдачи удостоверения'}
              name={'issue_date'}
              type={'date'}
              sx={{
                width: '300px',
              }}
              value={issueDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {setIssueDate(e.target.value)}}
            />

            <Stack
              flexDirection={'row'}
              sx={{
                width: '300px'
              }}
              gap={2}
            >
              <Checkbox
                sx={{
                  width: '50px',
                  height: '50px'
                }}
                checked={agreed}
                onChange={(e) => {setAgreed(e.target.checked)}}
              />

              <Typography>
                В соответствии с Законом РК "О персональных данных и их защите" в случае необходимости получения информации в отношении третьего лица подтверждаю, что имеется согласие данного лица
              </Typography>
            </Stack>

            <LoadingButton
              variant={'contained'}
              sx={{
                width: 'fit-content',
                px: '50px'
              }}
              disabled={!iin}
              loading={studentsByIINLoading}
              onClick={() => {
                handleFetchStudents()
              }}
            >
              Проверить
            </LoadingButton>
          </Stack>

          {studentsByIIN && (
            <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              gap={1}
            >
              {(studentsByIIN?.Students.Student.length > 0 || studentsByIIN.Students) && studentsByIIN.Students.Student.map((student) => {
                return (
                  <StudentGroupCard
                    studentInfo={student}
                    handleStartExam={() => {}}
                    feePage={false}
                    quickFeePage={false}
                    integration={true}
                    attachmentModule={{is_enabled: true}}
                    key={student.id}
                  />
                )
              })}
              {studentsByIIN.Students.Student.length === 0 && (
                <Typography>
                  По данному ИИН не найдено данных об обучении. Пожалуйста, обратитесь в свою автошколу
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default FeePage;
