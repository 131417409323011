import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    deleteSchoolCascade,
    deleteSchoolDima,
    getSchoolAttributes,
    getSchoolBalance,
    getSchoolInfo
} from '../actions/api';
import {
    Alert,
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle, Grid, List, ListItem,
    Stack, Switch,
    SxProps,
    Theme,
    Typography, useTheme
} from '@mui/material';
import {DirectionsCar, ModeEditOutlined, Person, SensorDoor} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {CategoriesGrid, SubCategoriesGrid} from "../components/CategoriesGrid";
import SchoolIcon from '@mui/icons-material/School';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StorageIcon from '@mui/icons-material/Storage';
import {Card} from "../components/Card";
import {useSchoolStore} from "../store/SchoolStore";
import PageHeader from "../../global/components/PageHeader";
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import {formatDate} from "../../global/helpers/text";
import { useUserStore } from '../../global/store/UserStore';
import {wrapperSx} from "../../global/helpers/globalstyles";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useTranslation} from "react-i18next";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiChangeSchoolFeatureEnable, apiGetSchoolFeatureToggle} from "../../AdminPanel/actions/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import {SchoolAttributesType} from "../../global/actions/types.api";
import {toast} from "react-toastify";

const titleSx: SxProps<Theme> = {
    width: 181,
    fontWeight: 500,
    color: '#000',
    fontSize: 13
};

const valueSx: SxProps<Theme> = {
    fontSize: 13
};

const SchoolPage = () => {

    const navigate = useNavigate();
    const {id} = useParams();

    const { t } = useTranslation();

    const isAdmin = useUserStore((state) => state.isAdmin);
    const isSuperAdmin = useUserStore((state) => state.isSuperAdmin);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

    const currentUser = useUserStore((state) => state);
    const currentSchool = useSchoolStore((state) => state);
    const [schoolInfo, setSchoolInfo] = useState<any>({
        address: '',
        avatar: null,
        balance: 0,
        bin: '',
        categories: [],
        director: '',
        fts: '',
        id: 0,
        phone1: '',
        phone2: '',
        title: '',
        region: '',
        locality: '',
        user: {
            iin: '',
            email: '',
            phone: '',
            first_name: '',
            last_name: '',
            patronymic: ''
        }
    });
    const [schoolAttributes, setSchoolAttributes] = useState<SchoolAttributesType>({
        drivers: {
            total: 0,
            active: 0,
        },
        masters: {
            total: 0,
            active: 0,
        },
        teachers: {
            total: 0,
            active: 0
        },
        groups: {
            total: 0,
            active: 0,
        },
        transports: {
            total: 0,
        },
        base: {
            created_at: null,
            updated_at: null
        }
    });
    const [attributesLoading, setAttributesLoading] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [balance, setBalance] = useState<number>(0);
    const [alertVisible, setAlertVisible] = useState<boolean>(false);
    const [schoolInfoLoading, setSchoolInfoLoading] = useState<boolean>(false);

    const [theoryExamsFeature, theoryExamsFeatureLoading, theoryExamsFeatureError, fetchTheoryExamsFeature] = useLoadingHook(apiGetSchoolFeatureToggle);
    const [autoFillFeature, autoFillFeatureLoading, autoFillFeatureError, fetchAutoFillFeature] = useLoadingHook(apiGetSchoolFeatureToggle);
    const [changeFeature, changeFeatureLoading, changeFeatureError, requestChangeFeature] = useLoadingHook(apiChangeSchoolFeatureEnable);

    useEffect(() => {
        fetchSchoolInfo();

        if (isSuperAdmin) {
            fetchTheoryExamsFeature({
                school_id: id,
                module_name: 'theory_exams',
                is_enabled: false
            })

            fetchAutoFillFeature({
                school_id: id,
                module_name: 'auto_fill',
                is_enabled: false
            })
        }
    },[]);

    const fetchSchoolInfo = async () => {
        setSchoolInfoLoading(true);
        setAttributesLoading(true);
        try {
            const schoolInfoRes = await getSchoolInfo(Number(id));
            setSchoolInfo(schoolInfoRes.data);
            currentSchool.storeSchoolInfo(schoolInfoRes.data.id, schoolInfoRes.data.title);
            const balanceRes = await getSchoolBalance(Number(id));
            setBalance(balanceRes.data.balance);
            const attributesRes = await getSchoolAttributes(Number(id));
            setSchoolAttributes(attributesRes.data);
        } catch (err: any) {
            toast.error(err.response.data.message);
            navigate('/schools');
        } finally {
            setAttributesLoading(false);
            setSchoolInfoLoading(false);
        }
    };

    useEffect(() => {
        if (attributesLoading) {
            setAlertVisible(false);
        } else {
            if (schoolAttributes.teachers.active === 0 ||
                schoolAttributes.drivers.active === 0 ||
                schoolAttributes.masters.active === 0 ||
                schoolAttributes.transports.total === 0) {
                setAlertVisible(true);
            } else {
                setAlertVisible(false);
            }
        }
    }, [schoolAttributes, attributesLoading])

    const adminDeleteSchool = () => {
        setDeleteLoading(true);
        deleteSchoolCascade(schoolInfo.id)
            .then((res) => {
                navigate('/schools');
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => {
                setDeleteLoading(false);
            })
    }

    const deleteSchoolAndUser = () => {
        setDeleteLoading(true);
        deleteSchoolDima(schoolInfo.id)
            .then((res) => {
                navigate('/schools');
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => {
                setDeleteLoading(false);
            })
    }

    const handleTheoryExamsChangeEnabled = (checked: boolean) => {
        requestChangeFeature({
            is_enabled: checked,
            module_name: 'theory_exams',
            school_id: id
        })
    }

    const handleAutoFillChangeEnabled = (checked: boolean) => {
        requestChangeFeature({
            is_enabled: checked,
            module_name: 'auto_fill',
            school_id: id
        })
    }

    useEffect(() => {
        if (changeFeature) {
            fetchTheoryExamsFeature({
                school_id: id,
                module_name: 'theory_exams',
                is_enabled: false
            })
            fetchAutoFillFeature({
                school_id: id,
                module_name: 'auto_fill',
                is_enabled: false
            })
        } else if (changeFeatureError) {
            toast.error(changeFeatureError?.response?.data?.message ?? 'Произошла непредвиденная ошибка')
        }
    }, [changeFeature, changeFeatureError]);

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column', pb: 3}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        mt: '17px',
                        p: '32px',
                        background: '#fff',
                        width: 'fit-content',
                        borderRadius: '16px'
                    }}
                    gap={'32px'}
                >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Avatar
                            sx={{color: '#1E9AF3', bgcolor: '#f5f5f5', mr: 2, width: 50, height: 50, border: '1px solid #1E9AF3'}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                        <Typography variant={'h4'}>
                            {schoolInfo.title}
                        </Typography>
                    </Stack>
                    <Grid
                      className={'schools-info-grid'}
                    >
                        <section className={'school item-1'}>
                            <Typography className={'info-header'}><b>{t('school_page.school_data.title')}</b></Typography>

                            <Stack gap={'8px'}>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.bin_iin')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.bin}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.address')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo.region ? `${schoolInfo.region}, ` : ''}{schoolInfo.locality ? `${schoolInfo.locality}, ` : ''}{schoolInfo?.address}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.fio')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.director}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.director_iin')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.director_iin}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.director_qualification_certificate_number')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.director_qualification_certificate_number ?? 'Н/Д'}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.director_qualification_certificate_issuer')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.director_qualification_certificate_issuer ?? 'Н/Д'}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.director_qualification_certificate_issue_date')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.director_qualification_certificate_issue_date ? formatDate(schoolInfo?.director_qualification_certificate_issue_date) : 'Н/Д'}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.director_qualification_certificate_end_date')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.director_qualification_certificate_end_date ? formatDate(schoolInfo?.director_qualification_certificate_end_date) : 'Н/Д'}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.school_data.main_phone')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo?.phone1}</Typography>
                                </Stack>
                            </Stack>
                        </section>

                        <CategoriesGrid className={'item-2'} categories={schoolInfo.categories} />

                        <section className={'user item-3'}>
                            <Typography className={'info-header'}><b>{t('school_page.user_data.title')}</b></Typography>

                            <Stack gap={'8px'}>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.user_data.title')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo.user.iin}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.user_data.fio')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo.user.last_name ? `${schoolInfo.user.last_name} ` : ''}{schoolInfo.user.first_name} {schoolInfo?.patronymic}</Typography>
                                </Stack>
                                <Stack className={'info-row'} flexDirection="row">
                                    <Typography sx={titleSx}><b>{t('school_page.user_data.mail')}:</b></Typography>
                                    <Typography sx={valueSx}>{schoolInfo.user.email}</Typography>
                                </Stack>
                            </Stack>
                        </section>

                        <SubCategoriesGrid className={'item-4'} categories={schoolInfo.categories} />
                    </Grid>
                </Stack>

                <Box sx={{mt: 4}}>
                    <Typography variant="h4"  sx={{ml: 1}}>{t('school_page.database')}</Typography>
                    {alertVisible && (
                        <Alert severity={'error'} sx={{my: 1}}>
                            {t('school_page.alert')}:
                            <List sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                                {!schoolAttributes.base.updated_at && <li><Chip label={t('school_page.study_base')}/></li>}
                                {schoolAttributes.teachers.active === 0 && <li><Chip label={t('school_page.teachers')}/></li>}
                                {schoolAttributes.drivers.active === 0 &&
                                    <li><Chip label={t('school_page.drivers')}/></li>}
                                {schoolAttributes.masters.active === 0 &&
                                    <li><Chip label={t('school_page.masters')}/></li>}
                                {schoolAttributes.transports.total === 0 &&
                                    <li><Chip label={t('school_page.car')}/></li>}
                            </List>
                        </Alert>
                    )}

                    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}} gap={'16px'}>
                        <Card
                          title={t('school_page.change-info')}
                          icon={<ModeEditOutlined />}
                          footer={attributesLoading ? [] : []}
                          onClick={() => {navigate(`/schools/${schoolInfo.id}/change-info`)}}
                        />

                        <Card
                          title={t('school_page.cabinets')}
                          icon={<SensorDoor/>}
                          footer={attributesLoading ? [] : []}
                          onClick={() => {navigate(`/schools/${schoolInfo.id}/cabinets`)}}
                        />

                        <Card
                            title={t('school_page.teachers')}
                            icon={<PersonAddIcon/>}
                            footer={attributesLoading ? [] : [
                                {title: t('school_page.all'), value: schoolAttributes.teachers.total},
                                {title: t('school_page.current'), value: schoolAttributes.teachers.active}
                            ]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/teachers`)}}
                        />

                        <Card
                            title={t('school_page.drivers')}
                            icon={<PersonAddIcon/>}
                            footer={attributesLoading ? [] : [
                                {title: t('school_page.all'), value: schoolAttributes.drivers.total},
                                {title: t('school_page.current'), value: schoolAttributes.drivers.active}
                            ]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/drivers`)}}
                        />

                        <Card
                            title={t('school_page.masters')}
                            icon={<PersonAddIcon/>}
                            footer={attributesLoading ? [] : [
                                {title: t('school_page.all'), value: schoolAttributes.masters.total},
                                {title: t('school_page.current'), value: schoolAttributes.masters.active}
                            ]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/masters`)}}
                        />

                        <Card
                            title={t('school_page.car')}
                            icon={<DirectionsCar/>}
                            footer={attributesLoading ? [] : [
                                {title: t('school_page.all'), value: schoolAttributes.transports.total},
                            ]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/transport`)}}
                        />

                        <Card
                            title={t('school_page.group')}
                            icon={<SchoolIcon/>}
                            footer={attributesLoading ? [] : [
                                {title: t('school_page.all'), value: schoolAttributes.groups.total},
                                {title: t('school_page.current'), value: schoolAttributes.groups.active}
                            ]}
                            onClick={() => {navigate('/groups')}}
                        />

                        <Card
                          title={t('school_page.students')}
                          icon={<Person/>}
                          footer={[]}
                          onClick={() => {navigate(`/schools/${schoolInfo.id}/kursants`)}}
                        />

                        <Card
                            title={t('school_page.users')}
                            icon={<Person/>}
                            footer={[]}
                            onClick={() => {navigate(`/schools/${schoolInfo.id}/workers`)}}
                        />
                    </Stack>
                </Box>

                {/*/!* TODO: Разблокировать для юзеров после окончания разработки *!/*/}
                {/*{isAdmin && (<Box>*/}
                {/*    <Typography variant="h4" sx={{ml: 1}}>Модули в разработке</Typography>*/}
                {/*    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}}>*/}

                {/*        <Card*/}
                {/*            title="Транзакции"*/}
                {/*            icon={<AttachMoneyIcon/>}*/}
                {/*            footer={[]}*/}
                {/*            onClick={() => {navigate(`/schools/${schoolInfo.id}/transactions`)}}*/}
                {/*        />*/}

                {/*        <Card*/}
                {/*            title="Запись на вождение"*/}
                {/*            icon={<DirectionsCar/>}*/}
                {/*            footer={[]}*/}
                {/*            onClick={() => {navigate(`/schools/${schoolInfo.id}/driving`)}}*/}
                {/*        />*/}
                {/*    </Stack>*/}
                {/*</Box>)}*/}

                {isSuperAdmin && theoryExamsFeature && (<Box display={'flex'} flexDirection={'column'} gap={1} sx={{mt: 2}}>
                    <Typography variant="h4" sx={{ml: 1}}>Управление модулями системы</Typography>

                    <Stack
                        flexDirection={'row'}
                        gap={1}
                    >
                        <Stack
                          display={'flex'}
                          flexDirection={'column'}
                          sx={{
                              width: '300px',
                              height: 'auto',
                              minHeight: '150px',
                              borderRadius: '16px',
                              backgroundColor: '#FFFFFF',
                              py: '20px',
                              px: '16px',
                              gap: '16px'
                          }}
                          key={theoryExamsFeature?.id}
                        >
                            <Typography
                              fontSize={'14px'}
                              color={'gray'}
                            >
                                #{theoryExamsFeature?.module_name}
                            </Typography>
                            <Typography
                              fontSize={'14px'}
                              fontWeight={'bold'}
                            >
                                Состояние:
                            </Typography>
                            <Typography
                              fontSize={'14px'}
                              sx={{
                                  mt: '-16px'
                              }}
                            >
                                <Chip
                                  label={theoryExamsFeature?.is_enabled ? 'Включен' : 'Отключен'}
                                  color={theoryExamsFeature?.is_enabled ? 'success' : 'error'}
                                  sx={{
                                      mt: 1
                                  }}
                                />
                            </Typography>
                            <Typography
                              fontSize={'14px'}
                              fontWeight={'bold'}
                            >
                                Описание модуля:
                            </Typography>
                            <Typography
                              fontSize={'14px'}
                              sx={{
                                  mt: '-16px'
                              }}
                            >
                                {theoryExamsFeature?.description}
                            </Typography>
                            {(theoryExamsFeatureLoading || changeFeatureLoading) && (
                              <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                              >
                                  <CircularProgress />
                              </Stack>
                            )}
                            {theoryExamsFeature && (
                              <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                              >

                                  <Typography>
                                      Откл.
                                  </Typography>
                                  <Switch checked={theoryExamsFeature?.is_enabled} onChange={(e) => handleTheoryExamsChangeEnabled(e.target.checked)}/>
                                  <Typography>
                                      Вкл.
                                  </Typography>
                              </Stack>
                            )}
                        </Stack>

                        <Stack
                          display={'flex'}
                          flexDirection={'column'}
                          sx={{
                              width: '300px',
                              height: 'auto',
                              minHeight: '150px',
                              borderRadius: '16px',
                              backgroundColor: '#FFFFFF',
                              py: '20px',
                              px: '16px',
                              gap: '16px'
                          }}
                          key={autoFillFeature?.id}
                        >
                            <Typography
                              fontSize={'14px'}
                              color={'gray'}
                            >
                                #{autoFillFeature?.module_name}
                            </Typography>
                            <Typography
                              fontSize={'14px'}
                              fontWeight={'bold'}
                            >
                                Состояние:
                            </Typography>
                            <Typography
                              fontSize={'14px'}
                              sx={{
                                  mt: '-16px'
                              }}
                            >
                                <Chip
                                  label={autoFillFeature?.is_enabled ? 'Включен' : 'Отключен'}
                                  color={autoFillFeature?.is_enabled ? 'success' : 'error'}
                                  sx={{
                                      mt: 1
                                  }}
                                />
                            </Typography>
                            <Typography
                              fontSize={'14px'}
                              fontWeight={'bold'}
                            >
                                Описание модуля:
                            </Typography>
                            <Typography
                              fontSize={'14px'}
                              sx={{
                                  mt: '-16px'
                              }}
                            >
                                {autoFillFeature?.description}
                            </Typography>
                            {(autoFillFeatureLoading || changeFeatureLoading) && (
                              <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                              >
                                  <CircularProgress />
                              </Stack>
                            )}
                            {autoFillFeature && (
                              <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                              >

                                  <Typography>
                                      Откл.
                                  </Typography>
                                  <Switch checked={autoFillFeature?.is_enabled} onChange={(e) => handleAutoFillChangeEnabled(e.target.checked)}/>
                                  <Typography>
                                      Вкл.
                                  </Typography>
                              </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Box>)}

                <Dialog
                    open={deleteDialogOpen && isAdmin}
                    maxWidth={'xl'}
                >
                    <DialogTitle>
                        Удаление школы <b>{schoolInfo.title}</b>
                    </DialogTitle>
                    <DialogContent>

                        <Typography><i><b>Внимание!</b></i></Typography>
                        <Typography sx={{mt: 1}}><i>Если у прикрепленного к школе пользователя есть еще действующие школы - <b>пользователь не удалится</b>.</i></Typography>
                        <Typography sx={{mt: 1}}><i>Если данная школа является единственной прикрепленной к пользователю - <b>удалится и пользователь</b>.</i></Typography>

                        <Stack sx={{mt: 3}} flexDirection={'row'} gap={2} justifyContent={'space-between'}>
                            <Button
                                variant={'outlined'}
                                onClick={() => setDeleteDialogOpen(false)}
                                disabled={deleteLoading}
                            >
                                Отмена
                            </Button>

                            {!deleteLoading && (<Button
                                variant={'contained'}
                                color={'error'}
                                onClick={() => {adminDeleteSchool();}}
                                disabled={deleteLoading}
                            >
                                Подтвердить удаление
                            </Button>)}

                            {deleteLoading && (<CircularProgress color={'error'}/>)}
                        </Stack>
                    </DialogContent>
                </Dialog>

                <Dialog
                  maxWidth={'md'}
                  fullWidth={true}
                  open={schoolInfoLoading}
                >
                    <DialogTitle>
                        {t('school_page.ais-title')}
                    </DialogTitle>
                    <DialogContent>
                        <Stack flexDirection={'column'} gap={2}>
                            <Stack flexDirection={'row'} justifyContent={'center'}>
                                <Typography>{t('school_page.ais-content')}</Typography>
                            </Stack>
                            <Stack flexDirection={'row'} justifyContent={'center'}>
                                <CircularProgress />
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>
        </Box>
    );
};

export default SchoolPage;
