import React, { useEffect, useState } from 'react';
import {
    Badge,
    IconButton,
    Popover,
    List,
    ListItem,
    Typography,
    CircularProgress,
    Stack,
    Box,
    Tooltip, Divider
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import {getNewNotifications, markNotification} from '../actions/notifications.api';
import { useUserStore } from '../../global/store/UserStore';
import { useNavigate } from 'react-router-dom';
import { Notification } from '../actions/notifications.dto';
import {logoutFromSystem} from "../../global/actions/main.api";

const Notifications = () => {
    const navigate = useNavigate();
    const currentUser = useUserStore((state) => state);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [notifications, notificationsLoading, notificationsError, fetchNotifications] = useLoadingHook(getNewNotifications);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationMark = (notificationId: number) => {
        markNotification({
            user_id: Number(currentUser.id),
            notification_id: notificationId
        })
            .then((res) => {
                fetchNotifications(Number(currentUser.id))
            })
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (currentUser.id) {
            fetchNotifications(Number(currentUser.id));
        }
    }, []);

    // useEffect(() => {
    //     if (notificationsError) {
    //         logoutFromSystem();
    //     }
    // }, [notificationsError]);

    return (
        <Box>
            <IconButton color="inherit" onClick={handleClick}>
                <Badge badgeContent={notifications?.length} color="error">
                <NotificationsIcon />
                </Badge>
            </IconButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <List>
                {notifications && notifications.map((notification: Notification) => (
                    <ListItem key={notification.id}
                        onClick={() => {handleNotificationMark(Number(notification.id))}}
                        sx={{ cursor: 'pointer' }}
                    >
                        <Tooltip title={'Кликните, чтобы отметить как прочитанное'}>
                            <Stack gap={1}>
                                <Typography variant={'h6'}>{notification.title}</Typography>
                                <Typography>{notification.text}</Typography>
                                <Divider />
                            </Stack>
                        </Tooltip>
                    </ListItem>
                ))}
                {(notifications && notifications.length === 0) && (
                    <ListItem key={'no-new-notifications'}>
                        <Typography>Нет новых уведомлений</Typography>
                    </ListItem>
                )}
                {!notificationsLoading && (<ListItem key={'all-notifications'}
                    onClick={() => {navigate('/notifications')}}
                    sx={{ cursor: 'pointer' }}
                >
                    <Stack>
                        <Typography><b>Перейти ко всем уведомлениям</b></Typography>
                    </Stack>
                </ListItem>)}
                {(!notifications || notificationsLoading) && (
                    <Stack flexDirection={'row'} justifyContent={'center'}>
                        <CircularProgress />
                    </Stack>
                )}
                </List>
            </Popover>
        </Box>
    );
};

export default React.memo(Notifications);
