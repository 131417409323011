import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";
import {ProductType} from "../../global/actions/types.api";

export const apiGetProductPriceByProductId = (productId: string) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/products-prices/${productId}`);
}

export const apiGetProductsPrice = () => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/products-prices`);
}

export const apiRequestTransaction = (dto: TransactionCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/transactions/create`, dto);
}

export const apiRequestQuickTransaction = (dto: TransactionCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/transactions/create-quick-fee`, dto);
}

export const apiGetTransaction = (transactionId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/${transactionId}`);
}

export const apiGetQuickTransaction = (transactionId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/quick/${transactionId}`);
}

export const apiRefreshTransactionStatus = (transactionId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/refresh/${transactionId}`);
}

export const apiRefreshQuickTransactionStatus = (transactionId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/quick/refresh/${transactionId}`);
}

export const apiGetUserTransactions = (userId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/user/${userId}`);
}

export const apiGetFeeStudent = (studentId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/students/fee/${studentId}`);
}

export const apiGetStudentsByIin = (iin: string) => {
    return axiosInstance.get(`${BACKEND_URL}/students/quick-fee/${iin}`);
}

// ----------------- DTO

export interface TransactionCreateDto {
    user_id?: number;
    recipient_id: number | null;
    school_id?: number | null;
    type: ProductType;
    amount: number;
    price: number;
}