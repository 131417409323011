import React, { useEffect, useState } from 'react';
import {Alert, Button, Step, StepContent, StepLabel, Stepper, Typography} from '@mui/material';
import {GroupDto} from "../../actions/group.type";
import {createCertificatesForGroup, finishGroupApi, getServerDate, startExamsGroupApi} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import {convertToStandardDate, formatDate, stripTimeFromDate} from '../../../global/helpers/text';
import { useUserStore } from '../../../global/store/UserStore';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

interface GroupStepperProps {
    groupInfo: GroupDto;
    addStudent: () => void;
    formGroup: () => void;
    fetchGroupInfo: () => void;
}

const GroupStepper = ({groupInfo, addStudent, formGroup, fetchGroupInfo}: GroupStepperProps) => {

    const {t} = useTranslation();

    const isAdmin = useUserStore((state) => state.isAdmin);
    const userStore = useUserStore((state) => state);
    const [isAvailable, setIsAvailable] = useState<boolean>(false);

    useEffect(() => {
        if (groupInfo.status === 2) {
            getServerDate()
                .then((res) => {
                    const currentDate = res.data.current_date;
                    const currentDateObj = convertToStandardDate(currentDate);
                    const endDateObj = convertToStandardDate(formatDate(groupInfo.end_date));
                    const currentDateObjStripped = stripTimeFromDate(currentDateObj);
                    const endDateObjStripped = stripTimeFromDate(endDateObj);
                    console.log(currentDateObjStripped >= endDateObjStripped);
                    setIsAvailable(currentDateObjStripped >= endDateObjStripped);
                })
        }
    }, [groupInfo]);

    const startExam = () => {
        if (groupInfo.id) {
            startExamsGroupApi(groupInfo.id)
                .then(() => {
                    toast.success(t('school_page.group_page.group_stepper.messages.status_exam'));
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                })
                .finally(() => {
                    fetchGroupInfo();
                })
        }
    }

    const finishExam = () => {
        let examsDone = true;
        const students = groupInfo.students;

        for (let i = 0; i < students.length; i++) {
            const student = students[i];
            if (student.passed === null) {
                examsDone = false;
                break;
            }
        }

        if (!examsDone) {
            toast.error(t('school_page.group_page.group_stepper.messages.student_result'));
            return;
        }

        if (groupInfo.id) {
            finishGroupApi(groupInfo.id)
                .then(() => {
                    createCertificatesForGroup(Number(groupInfo.id))
                        .then((res) => {
                            console.log(res);
                        })
                        .catch((err) => {
                            toast.error(err.response.data.message ? err.response.data.message : 'Произошла ошибка');
                        })
                        .finally(() => {
                            fetchGroupInfo();
                        })
                    toast.success(t('school_page.group_page.group_stepper.messages.status_finished'));

                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                })
                .finally(() => {
                    fetchGroupInfo();
                })
        }
    }

    return (
        <Stepper orientation="vertical" activeStep={groupInfo.status - 1}>
            <Step>
                <StepLabel>{t('school_page.group_page.group_stepper.created.status')}</StepLabel>
                <StepContent>
                    <Typography>
                        {t('school_page.group_page.group_stepper.created.form_group_required')}
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        {t('school_page.group_page.group_stepper.created.add')}
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        {t('school_page.group_page.group_stepper.created.form_group')}
                    </Typography>
                    <Alert severity="warning" sx={{mt: 1, mb: 2}}>
                        {t('school_page.group_page.group_stepper.created.attention')}
                    </Alert>
                    {(<Button onClick={addStudent} variant={'contained'} disabled={userStore.isObserver || userStore.isAdmin}>
                        {t('school_page.group_page.group_stepper.created.add_student')}
                    </Button>)}
                    {(<Button onClick={formGroup} color={'success'} variant={'contained'} sx={{ml: 2}} disabled={userStore.isObserver}>
                        {t('school_page.group_page.group_stepper.created.form_group_btn')}
                    </Button>)}
                </StepContent>
            </Step>
            <Step>
                <StepLabel>{t('school_page.group_page.group_stepper.active.status')}</StepLabel>
                <StepContent>
                    <Typography>
                        {t('school_page.group_page.group_stepper.active.group_formed')}
                    </Typography>
                    <Typography>
                        {t('school_page.group_page.group_stepper.active.data_fixed')}
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        {t('school_page.group_page.group_stepper.active.finish_study')}
                    </Typography>
                    <Typography sx={{mb: 2.5}}>
                        {t('school_page.group_page.group_stepper.active.exam_students')}
                    </Typography>
                    {(<Button
                        onClick={startExam}
                        color={'success'}
                        variant={'contained'}
                        sx={{ml: 2}}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.group_stepper.active.finish_study_btn')}
                    </Button>)}
                </StepContent>
            </Step>
            <Step>
                <StepLabel>{t('school_page.group_page.group_stepper.exam.status')}</StepLabel>
                <StepContent>
                    <Typography sx={{mb: 1}}>
                        {t('school_page.group_page.group_stepper.exam.study_finished')}
                    </Typography>
                    <Typography sx={{mb: 0.5}}>
                        {t('school_page.group_page.group_stepper.exam.exam_chapter')}
                    </Typography>
                    <Typography sx={{mb: 2.5}}>
                        {t('school_page.group_page.group_stepper.exam.after_attestation')}
                    </Typography>
                    {(<Button onClick={finishExam} color={'warning'} variant={'contained'} sx={{ml: 2}}
                              disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.group_stepper.exam.complete')}
                    </Button>)}
                </StepContent>
            </Step>
            <Step>
                <StepLabel>{t('school_page.group_page.group_stepper.finished.status')}</StepLabel>
                <StepContent>
                    <Typography>{t('school_page.group_page.group_stepper.finished.exams_completed')}</Typography>
                </StepContent>
            </Step>
        </Stepper>
    );
};

export default GroupStepper;