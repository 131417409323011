import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import AuthPage from "./Auth/pages/AuthPage";
import SnackbarHOC from "./global/HOC/SnackbarHOC";
import RegisterPage from "./Auth/pages/RegisterPage";
import AdminPage from "./AdminPanel/pages/AdminPage";
import SchoolsList from './School/pages/SchoolsList';
import SchoolPage from './School/pages/SchoolPage';
import GroupsList from "./Group/pages/GroupsList";
import GroupPage from "./Group/pages/GroupPage";
import TransportPage from "./Transport/pages/TransportPage";
import ConfirmPage from './Confirm/pages/ConfirmPage';
import {Helmet} from "react-helmet";
import TicketsAdminPage from "./Tickets/pages/TicketsAdminPage";
import TicketsUserPage from "./Tickets/pages/TicketsUserPage";
import NewTicketPage from "./Tickets/pages/NewTicketPage";
import TicketMessages from "./Tickets/pages/TicketMessages";
import JournalPage from "./Journal/pages/JournalPage";
import BasePage from "./Base/pages/BasePage";
import NewSchoolAdmin from "./School/pages/NewSchoolAdmin";
import ChangeSchoolAdmin from "./School/pages/ChangeSchoolAdmin";
import UserPage from './Users/pages/UserPage';
import ChangePasswordList from "./Users/pages/ChangePasswordList";
import ChangeUserPassword from "./Users/pages/ChangeUserPassword";
import { createTheme, ThemeProvider } from '@mui/material';
import EmployeesPage from "./Employees/pages/EmployeesPage";
import GroupEnrollment from "./Group/pages/GroupEnrollment";
import GroupExamsProtocol from "./Group/pages/GroupExamsProtocol";
import GroupGraduation from "./Group/pages/GroupGraduation";
import StudentCertificate from "./Group/pages/StudentCertificate";
import SchoolTransactions from "./School/pages/SchoolTransactions";
import SchoolTopUpPage from "./School/pages/SchoolTopUpPage";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import ChangeUserInfo from './Users/pages/ChangeUserInfo';
import SchoolWorkers from "./School/pages/SchoolWorkers";
import StatisticsPage from './Statistic/pages/StatisticsPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdminCreateNotification } from './Notifications/pages/AdminCreateNotification';
import AllNotificationsPage from './Notifications/pages/AllNotificationsPage';
import CertificateCheckPage from "./Group/pages/CertificateCheckPage";
import EmployeeEditInfo from "./Employees/pages/EmployeeEditInfo";
import {QuestionsList} from "./Test/Question/pages/QuestionsList";
import QuestionEditForm from "./Test/Question/pages/QuestionEditForm";
import QuestionOverview from "./Test/Question/pages/QuestionOverview";
import BuyingPage from './Payments/pages/BuyingPage';
import SchoolLandingPage from "./Landing/pages/SchoolLandingPage";
import SchoolKursants from './School/pages/SchoolKursants';
import SchoolKursantPage from './School/pages/SchoolKursantPage';
import KursantDocsPDF from './School/pages/KursantDocsPDF';
import StudentCertificateA4 from './Group/pages/StudentCertificateA4';
import GovPage from './GovPage/pages/GovPage';
import SchoolCabinetsPage from './Cabinets/pages/SchoolCabinetsPage';
import TheoryExamPage from "./TheoryExam/pages/TheoryExamPage";
import TheoryExamResultsPage from "./TheoryExam/pages/TheoryExamResultsPage";
import TransactionPage from "./Payments/pages/TransactionPage";
import RegisterAsStudentPage from "./Auth/pages/RegisterAsStudentPage";
import TrialExamPage from "./TheoryExam/pages/TrialExamPage";
import TrialExamResultsPage from "./TheoryExam/pages/TrialExamResultsPage";
import StudentMainPage from "./Main/pages/StudentMainPage";
import SuperAdminModulesPage from "./AdminPanel/pages/SuperAdminModulesPage";
import SchoolChangeInfoPage from "./School/pages/SchoolChangeInfoPage";
import FeePage from "./Payments/pages/FeePage";
import AdminNewsRedactor from "./AdminPanel/pages/AdminNewsRedactor";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NewsPage from "./News/pages/NewsPage";
import ManagerMainPage from "./Main/pages/ManagerMainPage";
import QuickFeePage from "./Payments/pages/QuickFeePage";
import QuickFeePaymentPage from "./Payments/pages/QuickFeePaymentPage";
import QuickTransactionPage from "./Payments/pages/QuickTransactionPage";
import StudentsByIinPage from "./Landing/pages/StudentsByIinPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/auth",
        element: <AuthPage />
    },
    {
        path: "/register",
        element: <RegisterPage />
    },
    {
        path: "/register/student",
        element: <RegisterAsStudentPage />
    },
    // MAIN PAGES (STUDENT/ADMIN)
    {
        path: "/main/student",
        element: <StudentMainPage />
    },
    {
        path: "/main/manager",
        element: <ManagerMainPage />
    },
    {
        path: "/fee/:studentId",
        element: <FeePage />
    },
    {
        path: "/quick-fee/",
        element: <QuickFeePage />
    },
    {
        path: "/quick-fee/:studentId",
        element: <QuickFeePaymentPage />
    },
    // ADMIN PAGES
    {
        path: "/admin",
        element: <AdminPage />
    },
    {
        path: "/admin/notifications",
        element: <AdminCreateNotification />
    },
    {
        path: "/admin/news-redactor",
        element: <AdminNewsRedactor />
    },
    {
        path: "/admin/modules",
        element: <SuperAdminModulesPage />
    },
    {
        path: "/notifications",
        element: <AllNotificationsPage />
    },
    {
        path: "/news/:newsId",
        element: <NewsPage />
    },
    {
        path: "/schools",
        element: <SchoolsList />
    },
    {
        path: "/schools/:id",
        element: <SchoolPage />
    },
    {
        path: "/schools/:id/change-by-admin",
        element: <ChangeSchoolAdmin />
    },
    {
        path: "/groups",
        element: <GroupsList />
    },
    {
        path: "/groups/:id",
        element: <GroupPage />
    },
    {
        path: "/schools/:id/teachers",
        element: <EmployeesPage employee={'teachers'}/>
    },
    {
        path: "/schools/:id/drivers",
        element: <EmployeesPage employee={'drivers'}/>
    },
    {
        path: "/schools/:id/masters",
        element: <EmployeesPage employee={'masters'}/>
    },
    {
        path: "/schools/:id/employees/:employeeId",
        element: <EmployeeEditInfo />
    },
    {
        path: "/schools/:id/transport",
        element: <TransportPage />
    },
    {
        path: "/schools/:id/workers",
        element: <SchoolWorkers />
    },
    {
        path: "/schools/:id/kursants",
        element: <SchoolKursants />
    },
    {
        path: "/schools/:id/kursants/:kursantId",
        element: <SchoolKursantPage />
    },
    {
        path: "/schools/:id/kursants/:kursantId/:type",
        element: <KursantDocsPDF />
    },
    {
        path: "/confirm/:id",
        element: <ConfirmPage />
    },
    {
        path: "/admin/tickets",
        element: <TicketsAdminPage />
    },
    {
        path: "/tickets",
        element: <TicketsUserPage />
    },
    {
        path: "/tickets/new",
        element: <NewTicketPage />
    },
    {
        path: "/tickets/:id",
        element: <TicketMessages />
    },
    {
        path: "/journal",
        element: <JournalPage />
    },
    {
        path: "/schools/:id/base",
        element: <BasePage />
    },
    {
        path: "/schools/:id/transactions",
        element: <SchoolTransactions />
    },
    {
        path: "/schools/:id/topup",
        element: <SchoolTopUpPage />
    },
    {
        path: "/new-school",
        element: <NewSchoolAdmin />
    },
    {
        path: "/user/:id",
        element: <UserPage />
    },
    {
        path: "/change-password",
        element: <ChangePasswordList />
    },
    {
        path: "/change-password/:id",
        element: <ChangeUserPassword />
    },
    {
        path: "/change-userinfo/:id",
        element: <ChangeUserInfo />
    },
    {
        path: "/statistic",
        element: <StatisticsPage />
    },
    {
        path: "/enrollment/:id",
        element: <GroupEnrollment />
    },
    {
        path: "/exams-protocol/:id",
        element: <GroupExamsProtocol />
    },
    {
        path: "/graduation/:id",
        element: <GroupGraduation />
    },
    {
        path: "/certificate/:id",
        element: <CertificateCheckPage />
    },
    {
        path: "/certificate/pdf/:id",
        element: <StudentCertificate />
    },
    {
        path: "/certificate/A4/pdf/:id",
        element: <StudentCertificateA4 />
    },
    {
        path: "/questions",
        element: <QuestionsList />
    },
    {
        path: "/questions/new",
        element: <QuestionEditForm />
    },
    {
        path: "/questions/:id",
        element: <QuestionEditForm />
    },
    {
        path: "/questions/demo",
        element: <QuestionOverview />
    },
    {
        path: "/questions/demo/:id",
        element: <QuestionOverview />
    },
    {
        path: "/purchase",
        element: <BuyingPage />
    },
    {
        path: "/transactions/:transactionId",
        element: <TransactionPage />
    },
    {
        path: "/quick-transactions/:transactionId",
        element: <QuickTransactionPage />
    },
    {
        path: "/landing",
        element: <SchoolLandingPage />
    },
    {
        path: "/gov",
        element: <GovPage />
    },
    {
        path: "/schools/:id/cabinets",
        element: <SchoolCabinetsPage/>
    },
    {
        path: "/schools/:id/change-info",
        element: <SchoolChangeInfoPage />
    },
    {
        path: "/theory-exams/:id",
        element: <TheoryExamPage />
    },
    {
        path: "/theory-exams/results/:id",
        element: <TheoryExamResultsPage />
    },
    {
        path: "/trial-exams/:id",
        element: <TrialExamPage />
    },
    {
        path: "/trial-exams/results/:id",
        element: <TrialExamResultsPage />
    },
    {
        path: "/integration/find",
        element: <StudentsByIinPage />
    }
]);

const theme = createTheme({
    palette: {
        primary: {
            light: '#1E9AF3',
            main: '#1E9AF3',
            dark: '#1a8bda',
            contrastText: '#fff',
        },
        secondary: {
            light: '#05304F',
            main: '#05304F',
            dark: '#04263c',
            contrastText: '#fff',
        },
        info: {
            light: '#000000',
            main: '#000000',
            dark: '#000000',
            contrastText: '#fff'
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '41px',
                    height: '56px',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    border: '1px solid #99999F',
                    borderRadius: '16px',
                    backgroundColor: '#FFFFFF',
                    '-webkit-box-shadow': 'none'
                },
                input: {
                    borderRadius: '16px',
                    backgroundColor: '#FFFFFF !important',
                    height: '56px',
                    padding: '0 10px',
                    '-webkit-background-clip': 'text',
                    transition: 'background-color 5000s ease-in-out 0s',
                    boxShadow: 'none',
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        border: '0px !important'
                    },
                    '& .MuiInputBase-input': {
                        height: 'auto',
                    },
                },
            },
        },
    }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
<ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SnackbarHOC>
                    <Helmet>
                      <meta name="mailru-domain" content="CWH8CeLkeUIy2vTk" />
                    </Helmet>
                    <RouterProvider router={router}/>
                    <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                </SnackbarHOC>
                </LocalizationProvider>
            </I18nextProvider>
        </ThemeProvider>
);
