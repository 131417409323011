import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useUserStore } from '../../global/store/UserStore';
import PageHeader from '../../global/components/PageHeader';
import {GroupInfoType, ProductType, PurchaseType} from "../../global/actions/types.api";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiGetProductsPrice, apiRequestTransaction} from "../actions/api";
import {wrapperSx} from "../../global/helpers/globalstyles";
import LoadingButton from "../../global/components/LoadingButton";
import VisaIcon from "../../global/assets/images/VisaIcon";
import MasterCardIcon from "../../global/assets/images/MasterCardIcon";
import {apiGetExamGroups} from "../../Group/actions/api";
import {apiGetFeatureEnabled} from "../../global/actions/main.api";

const BuyingPage = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const snackbar = useSnackbarStore((state) => state);
  const currentUser = useUserStore((state) => state);

  const [trialModuleInfoResponse, trialModuleInfoLoading, trialModuleInfoError, requestTrialModuleInfo] = useLoadingHook(apiGetFeatureEnabled);

  const [productsPriceResponse, productsPriceLoading, productsPriceError, requestProductsPrice] = useLoadingHook(apiGetProductsPrice);
  const [iokaTransactionResponse, iokaTransactionLoading, iokaTransactionError, requestIokaTransaction] = useLoadingHook(apiRequestTransaction);
  const [examGroupsResponse, examGroupsLoading, examGroupsError, requestExamGroups] = useLoadingHook(apiGetExamGroups);

  const [currentPrice, setCurrentPrice] = useState<number | null>(null);

  const [purchaseInfo, setPurchaseInfo] = useState<PurchaseType>({
    recipient_id: null,
    school_id: null,
    type: '',
    amount: 1,
    price: 0,
    group_id: ''
  });

  useEffect(() => {
    if (location?.state?.purchaseType) {
      setPurchaseInfo((prev) => {
        return {
          ...prev,
          type: location.state.purchaseType,
        }
      });
    }

    if (location?.state?.group_id) {
      setPurchaseInfo((prev) => {
        return {
          ...prev,
          group_id: location?.state?.group_id,
        }
      })
    }
  }, [location.state]);

  useEffect(() => {
    requestProductsPrice();
    requestExamGroups();
    requestTrialModuleInfo('trial_exams');
  }, []);

  const handleTypeSelectChange = (value: ProductType) => {
    setPurchaseInfo((prev) => {
      return {
        ...prev,
        type: value,
      }
    });
  };

  const handleGroupSelectChange = (e: SelectChangeEvent) => {

    console.log(`value: ${e.target.value}`)

    setPurchaseInfo((prev) => {
      return {
        ...prev,
        group_id: e.target.value,
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === 'amount' && Number(value) <= 0) {
      snackbar.errorMessage('Количество попыток должно варьироваться от 1 до 999')
      setPurchaseInfo((prev) => {
        return {
          ...prev,
          [name]: 1,
        }
      })
    } else {
      setPurchaseInfo((prev) => {
        return {
          ...prev,
          [name]: value,
        }
      })
    }
  };

  useEffect(() => {
    if (purchaseInfo.type && productsPriceResponse) {
      const currentProductPrice = productsPriceResponse.find((productPrice: any) => productPrice.id === purchaseInfo.type)?.price

      setCurrentPrice(currentProductPrice);
    }
  }, [productsPriceResponse, purchaseInfo.type]);

  useEffect(() => {
    if (currentPrice) {
      setPurchaseInfo((prev) => {
        return {
          ...prev,
          price: (Number(purchaseInfo.amount) * Number(currentPrice)),
        }
      })
    }
  }, [currentPrice, purchaseInfo.amount]);

  const handleKaspiClick = () => {

  }

  const handleIOKAClick = () => {

    if (currentUser.id && purchaseInfo.type) {
      requestIokaTransaction({
        user_id: Number(currentUser.id),
        recipient_id: currentUser.id,
        school_id: null,
        type: purchaseInfo.type,
        amount: purchaseInfo.amount,
        price: purchaseInfo.price,
        group_id: purchaseInfo.group_id ? purchaseInfo.group_id : null,
        payment_system: 'freedom'
      })
    } else {
      snackbar.errorMessage('Отсутствует currentUser.id || purchaseInfo.type')
    }
  }

  useEffect(() => {
    if (iokaTransactionResponse) {
      console.log(iokaTransactionResponse)
      // Переход на внешнюю ссылку
      if (iokaTransactionResponse.payment_url) {
        window.location.href = iokaTransactionResponse.payment_url;
      }
    }

    if (iokaTransactionError) {
      snackbar.errorMessage(iokaTransactionError?.response?.data?.message ?? 'Ошибка')
    }
  }, [iokaTransactionResponse, iokaTransactionError]);

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>

      <Box sx={{...wrapperSx}}>
        <Stack
            direction={'column'}
            width={'100%'}
            sx={{
              mt: '38px',
              p: '52px',
              borderRadius: '24px',
              background: '#F5F5F5',
              gap: '16px'
            }}
        >
          <Typography
            variant={'h6'}
          >
            Приобретение {purchaseInfo?.type === 'theory_exams' && 'попыток прохождения внутришкольного экзамена'} {purchaseInfo?.type === 'trial_exams' && 'прохождения пробного тестирования'}
          </Typography>

          <TextField
              label="Количество попыток"
              value={purchaseInfo.amount}
              name={'amount'}
              type={'number'}
              onChange={handleInputChange}
              sx={{
                width: '500px'
              }}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 999
                }
              }}
          />

          <FormControl fullWidth>
            <InputLabel id={`description-label`}>Тип приобретения</InputLabel>
            <Select
                labelId={`description-label`}
                value={purchaseInfo.type}
                onChange={(e) => ((e.target.value === 'theory_exams' || e.target.value === 'trial_exams') && handleTypeSelectChange(e.target.value))}
                label="Тип приобретения"
                sx={{
                  width: '500px',
                  border: 'none',
                }}
            >
              <MenuItem value="theory_exams">Внутришкольные экзамены</MenuItem>
              {trialModuleInfoResponse?.is_enabled && <MenuItem value="trial_exams">Пробное тестирование</MenuItem>}
            </Select>
          </FormControl>

          {
            purchaseInfo?.type === 'theory_exams' && examGroupsResponse && (
                  <FormControl fullWidth>
                    <InputLabel id={`description-label`}>Учебная группа</InputLabel>
                    <Select
                        labelId={`description-label`}
                        value={purchaseInfo?.group_id ? purchaseInfo?.group_id : ''}
                        onChange={handleGroupSelectChange}
                        label="Учебная группа"
                        sx={{
                          width: '500px',
                          border: 'none',
                        }}
                    >
                      {examGroupsResponse.map((group : GroupInfoType) => (
                          <MenuItem value={`${group.id}`} key={group.id}>Группа "{group?.title}" (Категория - {group.category_id}). Школа "{group?.school?.title}"</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              )
          }

          <Typography fontSize={'14px'}>
            Стоимость одной попытки - {currentPrice} ₸
          </Typography>

          <Divider />

          <Stack>
            <Typography>
              Сумма к оплате:
            </Typography>
            <Typography variant={'h6'}>
              {purchaseInfo.price} ₸
            </Typography>
          </Stack>

          <Divider />

          <Typography>
            Выберите способ оплаты:
          </Typography>

          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            gap={'16px'}
          >
            {/*<LoadingButton*/}
            {/*  variant={'contained'}*/}
            {/*  color={'info'}*/}
            {/*  sx={{*/}
            {/*    background: '#fa4747',*/}
            {/*    width: '300px',*/}
            {/*    '&:hover': {*/}
            {/*      background: '#bf2d2d'*/}
            {/*    },*/}
            {/*  }}*/}
            {/*  onClick={() => {handleKaspiClick()}}*/}
            {/*  loading={productsPriceLoading}*/}
            {/*>*/}
            {/*  <KaspiIcon width={'35px'} height={'35px'}/>*/}
            {/*  <Typography*/}
            {/*    sx={{*/}
            {/*      ml: '16px',*/}
            {/*      color: '#fff'*/}
            {/*    }}*/}
            {/*    fontWeight={'bold'}*/}
            {/*  >*/}
            {/*    Kaspi.kz*/}
            {/*  </Typography>*/}
            {/*</LoadingButton>*/}

            <LoadingButton
                variant={'contained'}
                sx={{
                  width: '300px',
                  gap: '16px'
                }}
                onClick={() => {handleIOKAClick()}}
                loading={productsPriceLoading || iokaTransactionLoading}
            >
              <VisaIcon width={'50px'} height={'50px'}/>
              <MasterCardIcon width={'50px'} height={'50px'}/>
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default BuyingPage;
