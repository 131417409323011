import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Paper,
    Select, SelectChangeEvent,
    Stack,
    SxProps,
    Theme, Tooltip,
    Typography
} from "@mui/material";
import {GroupDto} from "../../actions/group.type";
import {getActiveSchoolTeachers} from "../../../Employees/actions/api";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {attachTeacherToGroup, detachTeacherFromGroup} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import ClearIcon from '@mui/icons-material/Clear';
import { useUserStore } from '../../../global/store/UserStore';
import {useTranslation} from "react-i18next";

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface TeachersWindowProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
}

interface TeachersModalProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
    open: boolean;
    onClose: () => void;
}

const TeachersModal = ({groupInfo, fetchGroupInfo, open, onClose} : TeachersModalProps) => {

    const {t} = useTranslation();

    const [teachersList, setTeachersList] = useState<any[]>([]);
    const [teacherId, setTeacherId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    useEffect(() => {
        getActiveSchoolTeachers(Number(schoolInfo.id))
            .then((res) => {
                console.log(res);
                setTeachersList(res.data);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
    }, []);

    const handleSelectChange = (e: SelectChangeEvent) => {
        setTeacherId(e.target.value);
    }

    const handleAttach = () => {

        if (!groupInfo.id || !teacherId) {
            snackbar.errorMessage(t('school_page.group_page.attributes_window.modal.required.teacher'));
            return;
        }

        const teacherDto = {
            group_id: groupInfo.id,
            teacher_id: Number(teacherId)
        }

        attachTeacherToGroup(teacherDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {t('school_page.group_page.attributes_window.modal.add_dialog.teacher')}<i><b>{groupInfo.title}</b></i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={teacherId}
                    >
                        {teachersList?.map((teacher) => {
                            return (
                                <MenuItem value={teacher.id} key={teacher.id}>
                                    <Stack sx={{width: '100%'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {teacher.last_name[0]}{teacher.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack flexDirection="row" alignItems="center" justifyContent="space-between"
                                                   sx={{width: '100%'}}>
                                                <Typography>{teacher.last_name} {teacher.first_name}</Typography>
                                                <Typography variant="body2" color="gray">#{teacher.id}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            )
                        })}
                    </Select>

                    <Typography variant="body2" sx={{mt: 1}}>
                        {t('school_page.group_page.attributes_window.modal.reminder.teacher')}
                    </Typography>

                    <Button onClick={handleAttach}>
                        {t('school_page.group_page.attributes_window.modal.add_dialog.teacher')}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

const TeachersWindow = ({groupInfo, fetchGroupInfo} : TeachersWindowProps) => {

    const {t} = useTranslation();

    const isAdmin = useUserStore((state) => state.isAdmin);
    const userStore = useUserStore((state) => state);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleDetach = () => {
        const teacherDto = {
            teacher_id: groupInfo.teacher.teacher.id,
            group_id: groupInfo.id
        }

        detachTeacherFromGroup(teacherDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>

                <Typography variant="h4">{t('school_page.group_page.attributes_window.window.this_group.teacher')}</Typography>
                <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                    {t('school_page.group_page.attributes_window.window.this_group.teacher')}
                </Typography>
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    {groupInfo.teacher?.length === 0 && (
                        <Typography
                            fontSize={'14px'}
                            color={"gray"}
                        >
                             {t('school_page.group_page.attributes_window.window.no_data')}
                        </Typography>
                    )}

                    {groupInfo.teacher.length > 0 && (
                        groupInfo.teacher.map((teacher: any) => {
                            return (
                                <>
                                    <Stack sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {teacher.teacher.last_name[0]}{teacher.teacher.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack flexDirection="row" alignItems="center" justifyContent="start"
                                                   sx={{width: '100%'}}>
                                                <Typography>{teacher.teacher.last_name} {teacher.teacher.first_name}</Typography>
                                                <Typography variant="body2" color="gray" sx={{ml: 1}}>#{teacher.teacher.id}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </>
                            )
                        })
                    )}
                    <Button
                        onClick={() => {setIsDialogOpen(true)}}
                        sx={{
                            width: 'fit-content',
                            ml: '16px'
                        }}
                        variant={'contained'}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.attributes_window.modal.add_btn.teacher')}
                    </Button>
                </Stack>
            </Paper>

            <TeachersModal
                open={isDialogOpen}
                groupInfo={groupInfo}
                fetchGroupInfo={fetchGroupInfo}
                onClose={() => {setIsDialogOpen(false)}}
            />
        </Box>
    );
};

export default TeachersWindow;