import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputBase,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent, Stack, TextField, Typography
} from '@mui/material';
import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {IRegisterSchool} from "../dto/AuthDto";

interface FirstStepProps {
    schoolForm: IRegisterSchool;
    handleSchoolChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    registerLoading: boolean,
    uploadedImages: any[],
    uploadedFiles: any[],
    handleImageEvent: (e: ChangeEvent<HTMLInputElement>) => void,
    handleFileEvent: (files: File[]) => void,
    handleCategoriesChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleIsGovChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    nextStep: (step: number) => void,
    detach: (type: string, index: number) => void,
    regionsArray: any[],
    setRegion: (region: any) => void,
    localitiesArray: any[],
    setLocality: (locality: any) => void,
    typesArray: any[],
    setType: (type: any) => void
}

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

export const FirstStep = ({detach, schoolForm, nextStep, handleSchoolChange, registerLoading, uploadedImages,
                          uploadedFiles, handleImageEvent, handleFileEvent, handleCategoriesChange, handleIsGovChange, regionsArray,
                          localitiesArray, setRegion, setLocality, typesArray, setType}: FirstStepProps) => {

    const { t } = useTranslation();

    const onDropRejected = (fileRejections: any) => {
        fileRejections.forEach((rejection: any) => {
            rejection.errors.forEach((error: any) => {
                if (error.code === 'file-too-large') {
                    alert(t('auth.first-step.file-too-large'));
                } else if (error.code === 'too-many-files') {
                    alert(t('auth.first-step.too-many-files'));
                } else if (error.code === 'file-invalid-type') {
                    alert(t('auth.first-step.file-invalid-type'));
                }
            });
        });
    };

    const {
        acceptedFiles, isDragActive, open, getRootProps,
        getInputProps, isFocused, isDragAccept, isDragReject
    } = useDropzone({
        accept: {
            'application/pdf': ['.pdf']
        },
        maxFiles: 1,
        maxSize: 5242880,
        onDropRejected
    });

    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        setFiles(acceptedFiles);
        handleFileEvent(acceptedFiles);
    }, [acceptedFiles]);

    const imagesRef = useRef<HTMLInputElement>(null);
    const filesRef = useRef<HTMLInputElement>(null);

    const handleRegionChange = (e: SelectChangeEvent<any>) => {
        setRegion(e.target.value)
    }

    const handleLocalitiesChange = (e: SelectChangeEvent<string>) => {
        setLocality(e.target.value)
    }

    const handleTypeChange = (e: SelectChangeEvent<string>) => {
        setType(e.target.value)
    }

    const handleNextStep = () => {
        if (schoolForm.type === 'natural' && files.length === 0) {
            toast.error(t('auth.first-step.certificate-required'))
            return;
        }

        nextStep(1);
    }

    return (
        <Stack flexDirection={'column'}>
            <Stack flexDirection={'row'} sx={{p: '36px', background: '#FFF'}} gap={'36px'}>
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography className={'info-header'} ><b>{t('auth.first-step.organization')}</b></Typography>

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.first-step.bin')}
                        value={schoolForm.bin}
                        name={'bin'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.first-step.title')}
                        value={schoolForm.title}
                        name={'title'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.first-step.director')}
                        value={schoolForm.director}
                        name={'director'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                      type={'text'}
                      placeholder={t('auth.first-step.director_iin')}
                      value={schoolForm.director_iin}
                      name={'director_iin'}
                      onChange={handleSchoolChange}
                      inputProps={{style: inputStyle}}
                      required={true}
                      disabled={registerLoading}
                    />

                    <InputBase
                      type={'text'}
                      placeholder={t('auth.first-step.director_qualification_certificate_number')}
                      value={schoolForm.director_qualification_certificate_number}
                      name={'director_qualification_certificate_number'}
                      onChange={handleSchoolChange}
                      inputProps={{style: inputStyle}}
                      required={true}
                      disabled={registerLoading}
                    />

                    <InputBase
                      type={'text'}
                      placeholder={t('auth.first-step.director_qualification_certificate_issuer')}
                      value={schoolForm.director_qualification_certificate_issuer}
                      name={'director_qualification_certificate_issuer'}
                      onChange={handleSchoolChange}
                      inputProps={{style: inputStyle}}
                      required={true}
                      disabled={registerLoading}
                    />

                    <TextField
                      name={'director_qualification_certificate_issue_date'}
                      type={'date'}
                      value={schoolForm.director_qualification_certificate_issue_date}
                      onChange={handleSchoolChange}
                      label={t('auth.first-step.director_qualification_certificate_issue_date')}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                              borderRadius: '16px',
                              border: "1px solid #99999F"
                          }
                      }}
                    />

                    <TextField
                      name={'director_qualification_certificate_end_date'}
                      type={'date'}
                      value={schoolForm.director_qualification_certificate_end_date}
                      onChange={handleSchoolChange}
                      label={t('auth.first-step.director_qualification_certificate_end_date')}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                              borderRadius: '16px',
                              border: "1px solid #99999F"
                          }
                      }}
                    />

                    <InputBase
                      type={'text'}
                      placeholder={t('auth.first-step.email')}
                      value={schoolForm.email}
                      name={'email'}
                      onChange={handleSchoolChange}
                      inputProps={{style: inputStyle}}
                      required={true}
                      disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.first-step.phone1')}
                        value={schoolForm.phone1}
                        name={'phone1'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.first-step.phone2')}
                        value={schoolForm.phone2}
                        name={'phone2'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={false}
                        disabled={registerLoading}
                    />

                    <FormControl
                        sx={{
                            border: '0'
                        }}
                    >
                        <InputLabel id={'type-label'}>{t('auth.first-step.type')}</InputLabel>
                        <Select
                          labelId={'type-label'}
                          onChange={handleTypeChange}
                          name={'type'}
                          label={t('auth.first-step.type')}
                          sx={{
                              borderRadius: '16px',
                              border: '0',
                              ".MuiOutlinedInput-notchedOutline": {
                                  border: '1px solid #99999F',
                                  borderRadius: '16px',
                              },
                          }}
                          value={schoolForm.type}
                          required={true}
                        >
                            {typesArray.map((type: any) => {
                                return (
                                  <MenuItem
                                    value={type.value}
                                    key={type.value}
                                  >
                                      {type.name}
                                  </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    {schoolForm.type === 'natural' && (
                        <Box
                            {...getRootProps({
                                className: "dropzone",
                                style: {
                                    padding: '20px',
                                    borderWidth: '2px',
                                    borderRadius: 2,
                                    borderColor: isFocused ? '#2196f3' : isDragAccept ? '#00e676' : isDragReject ? '#ff1744' : '#eeeeee',
                                    borderStyle: 'dashed',
                                    backgroundColor: '#fafafa',
                                },
                            })}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px'
                            }}
                        >
                            <input {...getInputProps()} />
                            <Box className="text-center"
                                 display="flex"
                                 flexDirection={"column"}
                                 gap={"10px"}
                                 alignItems="center"
                            >
                                {isDragActive ? (
                                    <p className="dropzone-content">
                                        {t('auth.first-step.release-mouse')}
                                    </p>
                                ) : (
                                    <p className="dropzone-content"
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >
                                        {t('auth.first-step.dropzone-content')}
                                    </p>
                                )}
                                <Button
                                    type="button"
                                    onClick={open}
                                    className="btn"
                                    variant={"contained"}
                                    sx={{width: "200px"}}
                                >
                                    {t('auth.first-step.choose-file')}
                                </Button>
                            </Box>
                            {uploadedFiles.map((file) => {
                                return (
                                    <aside style={{
                                        display: 'flex !important',
                                        flexWrap: 'wrap',
                                        width: 'auto',
                                        padding: '10px 20px',
                                    }}>
                                        <ul>{file.name} - {(Number(file.size)/1024).toFixed(1)} кбайт</ul>
                                    </aside>
                                )
                            })}
                        </Box>
                    )}
                    <FormControlLabel
                      className={'category-label'}
                      label={'Гос. учреждение - ?'}
                      control={
                        <Checkbox checked={schoolForm.is_gov} name={'is_gov'} onChange={handleIsGovChange} className={'register-checkbox'}/>
                      }
                      sx={{
                          '.MuiFormControlLabel-label': {
                              width: '300px'
                          },
                      }}
                    />

                    <Stack flexDirection={'column'}>
                        <Typography className={'info-header'} sx={{mb: '5px'}}><b>{t('auth.first-step.categories')}</b></Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                            <FormControlLabel className={'category-label'}
                                              control={<Checkbox checked={schoolForm.categories.includes('A1')}
                                                                 name={'A1'} onChange={handleCategoriesChange}
                                                                 className={'register-checkbox'}/>} label={'A1'}/>
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('A')} name={'A'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'А'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('B1')} name={'B1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B1'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('B')} name={'B'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('C1')} name={'C1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('C')} name={'C'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C'} />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('D1')} name={'D1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('D')} name={'D'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('BE')} name={'BE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'BE'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('C1E')} name={'C1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1E'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('CE')} name={'CE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'CE'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('DE')} name={'DE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'DE'} />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('D1E')} name={'D1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1E'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('Tm')} name={'Tm'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'Tm'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('Tb')} name={'Tb'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'Tb'} />
                        </Box>
                    </Stack>
                </Stack>
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography className={'info-header'}><b>{t('auth.first-step.organization-location')}</b></Typography>

                    <FormControl>
                        <InputLabel id={'region-label'}>{t('auth.first-step.region')}</InputLabel>
                        <Select
                          labelId={'region-label'}
                          onChange={handleRegionChange}
                          name={'region'}
                          label={t('auth.first-step.region')}
                          sx={{
                              borderRadius: '16px',
                              border: '0',
                              ".MuiOutlinedInput-notchedOutline": {
                                  border: '1px solid #99999F',
                                  borderRadius: '16px',
                              },
                          }}
                          value={schoolForm.region}
                          required={true}
                        >
                            {regionsArray.map((region: any) => {
                                return (
                                  <MenuItem
                                    value={region.name_ru}
                                    key={region.name_ru}
                                  >
                                      {region.name_ru}
                                  </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id={'locality-label'}>{t('auth.first-step.locality')}</InputLabel>
                        <Select
                          labelId={'locality-label'}
                          onChange={handleLocalitiesChange}
                          name={'locality'}
                          label={t('auth.first-step.locality')}
                          sx={{
                              borderRadius: '16px',
                              border: '0',
                              ".MuiOutlinedInput-notchedOutline": {
                                  border: '1px solid #99999F',
                                  borderRadius: '16px',
                              },
                          }}
                          value={schoolForm.locality ?? ''}
                          required={true}
                          disabled={localitiesArray.length === 0}
                        >
                            {localitiesArray.map((locality: any) => {
                                return (
                                  <MenuItem
                                    value={locality.name_ru}
                                    key={locality.name_ru}
                                  >
                                      {locality.name_ru}
                                  </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <InputBase
                      type={'text'}
                      placeholder={t('auth.first-step.address')}
                      value={schoolForm.address}
                      name={'address'}
                      onChange={handleSchoolChange}
                      inputProps={{style: inputStyle}}
                      required={false}
                      disabled={registerLoading}
                    />
                </Stack>
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'}>
                <Button
                    variant={'contained'}
                    sx={{width: '433px', height: '56px', mt: '33px'}}
                    onClick={() => {handleNextStep()}}
                >
                    {t('auth.first-step.next')}
                </Button>
            </Stack>
        </Stack>
    )
};